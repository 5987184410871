import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import "./qhse_policy.css";
//import "./navbar_footer.css";

gsap.registerPlugin(ScrollTrigger);

/* window.matchMedia('(min-width:767px) and (max-width: 992px)') */

let width_1 = window.matchMedia("(min-width: 1199px) and (max-width: 1700px)");
let width_2 = window.matchMedia("(min-width: 991px) and (max-width: 1199px)");
let width_3 = window.matchMedia("(min-width: 575px) and (max-width: 991px)");
let width_4 = window.matchMedia("(max-width: 575px)");
let width = $(document).width();

function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}
export default function Qhse_policy() {
  
  useTitle("PPS - Qhse Policy")
  const headerRef = useRef(null);
  
  const [Width, setWidth] = useState(width);

/*   useEffect(() => {
    width = $(document).width();
    setWidth(width);
    console.log(Width);
  }); */

  useEffect(() => {
    
    
    gsap
    .timeline()
    .from(".title", { autoAlpha: 0, y: -200, duration: 1, ease: "none" });

    if (width_1.matches) {
      console.log("width_1");
  
    
      gsap
      .timeline()
      .from(".text-box-1" ,{ scaleX: 0, transformOrigin: "left" , duration: 1})
      .from(".text-box-2" ,{ scaleX: 0, transformOrigin: "right" , duration: 1 }, "-=.2");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-1",
          start: "top center+=100",
          end: "top center-=100",
          //scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-3" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-2",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-4", { autoAlpha: 0, y: 500, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-1", { autoAlpha: 0, y: 100, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=300",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-2", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=250",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-3", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=200",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-4", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=150",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-5", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=100",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-6", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-7", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-8", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center-=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-9", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center+=500",
          end: "top center+=300",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-5" ,{ scaleX: 0, transformOrigin: "left" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center+=500",
          end: "top center+=400",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-6" ,{ scaleX: 0, transformOrigin: "right" }, "+=1");
    }
        
    if (width_2.matches) {
      console.log("width_2");
  
      gsap
      .timeline()
      .from(".text-box-1" ,{ scaleX: 0, transformOrigin: "left" , duration: 1})
      .from(".text-box-2" ,{ scaleX: 0, transformOrigin: "right" , duration: 1 }, "-=.2")
      .from(".text-box-3" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5");
  
/*       gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-1",
          start: "top center+=00",
          end: "top center-=100",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-2" ,{ scaleX: 0, transformOrigin: "right" }, "-=.2")
      .from(".text-box-3" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5"); */
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-2",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-4", { autoAlpha: 0, y: 500, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-1", { autoAlpha: 0, y: 100, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=300",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-2", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=250",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-3", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=200",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-4", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=150",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-5", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=100",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-6", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-7", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-8", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center-=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-9", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center+=700",
          end: "top center+=400",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-5" ,{ scaleX: 0, transformOrigin: "left" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center+=600",
          end: "top center+=400",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-6" ,{ scaleX: 0, transformOrigin: "right" }, "+=1");
    }
        
    if (width_3.matches) {
      console.log("width_3");
  
      gsap
      .timeline()
      .from(".text-box-1" ,{ scaleX: 0, transformOrigin: "left" , duration: 1})
      .from(".text-box-2" ,{ scaleX: 0, transformOrigin: "right" , duration: 1 }, "-=.2")
      .from(".text-box-3" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5");

  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-2",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-4", { autoAlpha: 0, y: 500, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-1", { autoAlpha: 0, y: 100, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=300",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-2", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=250",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-3", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=200",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-4", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=150",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-5", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=100",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-6", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-7", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-8", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center-=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-9", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center+=300",
          end: "top center-=00",
          //scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-5" ,{ scaleX: 0, transformOrigin: "left" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center+=300",
          end: "top center-=00",
          //scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-6" ,{ scaleX: 0, transformOrigin: "right" }, "-=1");
    }
        
    if (width_4.matches) {
      console.log("width_4");
    
      gsap
      .timeline()
      .from(".text-box-1" ,{ scaleX: 0, transformOrigin: "left" , duration: 1})
      .from(".text-box-2" ,{ scaleX: 0, transformOrigin: "right" , duration: 1 }, "-=.2")
      .from(".text-box-3" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5");


  

  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-2",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-4", { autoAlpha: 0, y: 500, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=350",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-1", { autoAlpha: 0, y: 100, duration: 1, ease: "none" });
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=300",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-2", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=250",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-3", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=200",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-4", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=150",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-5", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=100",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-6", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center+=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-7", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-8", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-3",
          start: "top center-=50",
          end: "top center",
          scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".li-9", { autoAlpha: 0, y: 100, duration: 1, ease: "none" }, "+=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center-=000",
          end: "top center-=400",
          //scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-5" ,{ scaleX: 0, transformOrigin: "left" }, "-=1");
  
      gsap.timeline({
        scrollTrigger: {
          trigger: ".text-box-4",
          start: "top center-=000",
          end: "top center-=200",
          //scrub: .5,
          toggleActions: 'play none none reverse'
        }
      })
      .from(".text-box-6" ,{ scaleX: 0, transformOrigin: "right" }, "-=1");
    }
    

    
/*           gsap.from(".text-box-3", {
            autoAlpha: 0
          }, {
            duration: 1, 
            autoAlpha: 1,
            ease: 'none',
            scrollTrigger: {
              markers: true,
              trigger: ".text-box-3",
              start: 'top center+=100',
              toggleActions: 'play none none reverse'
            }
          }); */
  });

  return (
    <div>
      <div id="qhse" className="content">
        <section className="u-clearfix u-section-1" id="carousel_7be1">
          <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
            <div className="u-layout">
              <div className="u-layout-col">
                <div
                  className="u-container-style u-image u-layout-cell u-right-cell u-size-60 u-image-1"
                  data-image-width={1280}
                  data-image-height={682}
                >
                  <div className="u-container-layout u-valign-bottom u-container-layout-1">
                    <h2 className="u-align-center u-text u-text-1 title">
                      QMS &amp; OHS &amp; EMS Integrated Policy
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="u-clearfix u-expanded-width-sm u-expanded-width-xs u-gutter-0 u-layout-wrap u-layout-wrap-2">
            <div className="u-layout">
              <div className="u-layout-col">
                <div className="u-align-justify-xs u-container-style u-layout-cell u-right-cell u-size-60 u-layout-cell-2">
                  <div className="u-container-layout u-valign-middle-xl u-container-layout-2">
                    <blockquote className="u-align-justify-lg u-align-justify-md u-align-justify-sm u-align-justify-xl u-border-8 u-border-custom-color-2 u-text u-text-2 text-box-1">
                      <b>Pangea</b>
                      <b>&nbsp;</b>is an Egyptian company providing high quality
                      inspection and petroleum services; we are committed to
                      achieve the highest standards of quality, health, safety
                      and environment by applying the requirement of the
                      international standards ISO 9001:2015 QMS, ISO 45001:2018
                      and ISO 14001:2015 ems and compliance to all relevant
                      legislations and legal requirements.
                    </blockquote>
                    <blockquote className="u-align-justify-lg u-align-justify-md u-align-justify-sm u-align-justify-xl u-border-6 u-border-custom-color-2 u-text u-text-3 text-box-2">
                      <b>Customer focus</b> and continual improvement are our
                      main principles to ensure our customers’ satisfaction,
                      through meeting and exceeding their expectations.
                    </blockquote>
                    <blockquote className="u-align-justify-lg u-align-justify-md u-align-justify-sm u-align-justify-xl u-border-6 u-border-custom-color-2 u-text u-text-4 text-box-3">
                      <b>Customer focus</b> and continual improvement are our
                      main principles to ensure our customers’ satisfaction,
                      through meeting and exceeding their expectations.
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="u-clearfix u-expanded-width-sm u-expanded-width-xs u-gutter-0 u-layout-wrap u-layout-wrap-3">
            <div className="u-layout" style={{}}>
              <div className="u-layout-row" style={{}}>
                <div className="u-container-style u-layout-cell u-left-cell u-size-60 u-layout-cell-3">
                  <div className="u-container-layout u-container-layout-3">
                    <blockquote className="u-align-left u-border-8 u-border-custom-color-2 u-text u-text-5 text-box-4">
                      <b>PANGEA</b> is committed to meet all requirements of
                      standards mentioned above by:
                    </blockquote>
                    <ul className="u-align-left u-text u-text-body-color u-text-6">
                      <li className="li-1">
                        <span style={{ fontSize: "0.875rem" }}>
                          Integration of Quality, Health, Safety and Environment
                          management and risk management fundamentals in all
                          business processes.&nbsp;
                        </span>
                      </li>
                      <li className="li-2">
                        <span style={{ fontSize: "0.875rem" }}>
                          Providing customers with high quality services that
                          meets their requirements and relevant international
                          standards.
                        </span>
                      </li>
                      <li className="li-3">
                        <span style={{ fontSize: "0.875rem" }}>
                          Operating the business according to the requirements
                          by ISO 9001: 2015.
                        </span>
                      </li>
                      <li className="li-4">
                        <span style={{ fontSize: "0.875rem" }}>
                          Promoting the culture of continual improvement.
                        </span>
                      </li>
                      <li className="li-5">
                        <span style={{ fontSize: "0.875rem" }}>
                          Providing FRAME WORK AND resources required to achieve
                          the objectives.
                        </span>
                      </li>
                      <li className="li-6">
                        <span style={{ fontSize: "0.875rem" }}>
                          Environment protection including prevention of
                          pollution.
                        </span>
                      </li>
                      <li className="li-7">
                        <span style={{ fontSize: "0.875rem" }}>
                          Preventing the injury and ill health for all workers,
                          visitors and all people who are involved in our
                          business and activity.
                        </span>
                      </li>
                      <li className="li-8">
                        <span style={{ fontSize: "0.875rem" }}>
                          Complying with the laws and regulations.
                        </span>
                      </li>
                      <li className="li-9">
                        <span style={{ fontSize: "0.875rem" }}>
                          All employees shall comply with this policy within the
                          company and abide by the rules and policies of the
                          entities that are dealt with during their presence in
                          all boundaries where these policies are applied in.
                        </span>
                        <br />
                        {/*[if !supportLineBreakNewLine]*/}
                        <br />
                        {/*[endif]*/}
                      </li>
                    </ul>
                    <blockquote className="u-align-justify u-border-8 u-border-custom-color-2 u-text u-text-7 text-box-5">
                      <b>This policy</b> is communicated through PANGEA’s
                      personnel and interested parties and will be reviewed
                      regularly and updated if required.
                    </blockquote>
                    <blockquote className="u-align-justify u-border-6 u-border-custom-color-2 u-text u-text-8 text-box-6">
                      All PANGEA team members are responsible of applying this
                      policy.
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
