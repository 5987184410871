const paths = {
    none: "/",
    Home: "/",
    Work_gallery: "/work_gallery",
    Contact_us: "/contact_us",
    Service_1: "/service_1",
    Service_2: "/service_2",
    Service_3: "/service_3",
    Qhse_policy: "/qhse_policy",
    Draft_2: "/draft_2",
    Draft_3: "/draft_3",
    Draft_4: "/draft_4",
    Draft_5: "/draft_5",
    About: "/about",
};

/* const paths = {
    none: "/",
    Home: "/react/",
    Work_gallery: "/react/work_gallery",
    Contact_us: "/react/contact_us",
    Service_1: "/react/service_1",
    Service_2: "/react/service_2",
    Service_3: "/react/service_3",
    Qhse_policy: "/react/qhse_policy",
    Draft_2: "/react/draft_2",
    Draft_3: "/react/draft_3",
    Draft_4: "/react/draft_4",
    Draft_5: "/react/draft_5",
    About: "/react/about",
}; */

export default paths;
