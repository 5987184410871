import React, { useEffect} from "react";
import "./about_us.css";
import { gsap } from "gsap";


function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}
export default function About() {

  useTitle("PPS - About Us")
  useEffect(() => {
/*     gsap
          .timeline() */
    
          gsap
          .timeline()
          .from(".about-image", {opacity: 0, x: -500, duration: 1})
          .from(".about-h2", {opacity: 0, x: 500, duration: .5}, "-=.5")
          .from("blockquote" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5")
          .from("#about li" ,{ scaleX: 0, transformOrigin: "left" }, "-=.5")
          ;

        }, []);

  return (
    <div>
      <div id="about"
        className="content">
        <section className="u-clearfix u-section-1" id="carousel_6b6d">
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
              <div className="u-layout">
                <div className="u-layout-row">
                  <div className="u-align-center u-container-style u-layout-cell u-left-cell u-size-23-lg u-size-23-xl u-size-29-md u-size-29-sm u-size-29-xs u-layout-cell-1">
                    <div className="u-container-layout u-valign-middle u-container-layout-1">
                      <div
                        alt=""
                        className="u-image u-image-circle u-image-1 about-image"
                        src
                        data-image-width={1000}
                        data-image-height={799}
                      />
                    </div>
                  </div>
                  <div className="u-align-left-lg u-align-left-md u-align-left-xl u-container-style u-layout-cell u-right-cell u-size-31-md u-size-31-sm u-size-31-xs u-size-37-lg u-size-37-xl u-layout-cell-2">
                    <div className="u-container-layout u-container-layout-2">
                      <h2 className="u-align-center-sm u-align-center-xs u-text u-text-1 about-h2">
                        About Us
                      </h2>
                      <blockquote className="u-align-left-sm u-align-left-xs u-text u-text-2">
                        <span
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: 700,
                            color: "#e8730f",
                          }}
                          className="u-text-custom-color-4"
                        >
                          PANGEA
                        </span>
                        <span className="u-text-custom-color-4">{" "}
                          is an Egyptian Shareholding Company delivers a wide
                          range of integrated solutions for several fields:
                        </span>
                        <span className="u-text-black">
                          <br />
                        </span>
                      </blockquote>
                      <ul className="u-align-left-sm u-align-left-xs u-text u-text-black u-text-3">
                        <li>
                          Oil &amp; Gas fields.
                          <br />
                        </li>
                        <li>
                          Industrial sectors.
                          <br />
                        </li>
                        <li>Constructions sites &amp; Sea-Ports.&nbsp;</li>
                        <li>
                          Telecommunications sector.
                          <br />
                        </li>
                      </ul>
                      <blockquote className="u-align-left-sm u-align-left-xs u-text u-text-4">
                        <span className="u-text-black">
                          <span
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: 700,
                              color: "#e8730f",
                            }}
                            className="u-text-custom-color-4"
                          >
                            PANGEA
                          </span>{" "}
                          ensures the highest standards of safety and quality.
                        </span>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}