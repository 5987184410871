import React, { useEffect, useRef, useState } from "react";
import WorkGallery1 from "./work_Gallery_1";
import WorkGallery2 from "./work_Gallery_2";

function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}

export default function MyComponent() {

  useTitle("PPS - Work Gallery")
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)

    }
  }, []);
  /* return <div>Rendered at {dimensions.width} x {dimensions.height}</div> */




        
        return <WorkGallery1 />
        }
        //return <WorkGallery2 />
      
