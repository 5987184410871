import React, { useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import $ from "jquery";
import axios from "axios";
import { useMachine } from "@xstate/react";
import { Machine, assign } from "xstate";

import { gsap, Power2 } from "gsap";
import "./home.css";

let previousCardEl_id,
  previous_previousCardEl_id,
  nextCardEl_id,
  next_nextCardEl_id;

function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
}

const fetchData = () =>
    axios.post('https://pangeaps.com/uploads/home/home.json')
        .then(response => {
          if (response.status !== 200) {
            //console.log(response);
            return;
          }
          //console.log(response.data);
          return response.data;
          
        }).catch(console.log);

const dataMachine = Machine({
  id: "jsonFile",
  initial: "idle",
  context: {
    jsonFile: null,
    error: null,
  },
  states: {
    idle: {
      on: {
        FETCH: "loading",
      },
    },
    loading: {
      invoke: {
        id: "getUser",
        src: (context, event) => fetchData(),
        onDone: {
          target: "success",
          actions: assign({ jsonFile: (context, event) => event.data }),
        },
        onError: {
          target: "failure",
          actions: assign({ error: (context, event) => event.data }),
        },
      },
    },
    success: {},
    failure: {
      on: {
        RETRY: "loading",
      },
    },
  },
});

const Home = () => {
  useTitle("Pangea Petroleum Services - PPS");

  const [current, send] = useMachine(dataMachine);

  useEffect(() => {
    send("FETCH");
  }, [send]);

  var prev_button = useRef(null);
  var cardsContainerEl = useRef(null);
  var next_button = useRef(null);
  var cardInfosContainerEl = useRef(null);
  var appBgContainerEl = useRef(null);
  var prevBtnTimeline = gsap.timeline();
  var nextBtnTimeline = gsap.timeline();


  
  function initCardEvents() {
    const currentCardEl = document.querySelector(".current_card");
    currentCardEl.addEventListener("pointermove", updateCard);
    currentCardEl.addEventListener("pointerout", (e) => {
      resetCardTransforms(e);
    });
  }
  useEffect(() => {
    if (current.matches("success")) {

      //console.log(current.context.jsonFile.count);
      
      const { imagesLoaded } = window;
      const images = [...document.querySelectorAll("img")];
      const totalImages = images.length;
      let loadedImages = 0;
      const loaderEl = document.querySelector(".loader span");

      const arrowLength = 25;
      const prevBtnTop = prev_button.firstElementChild;
      const prevBtnBottom = prev_button.lastElementChild;

      const nextBtnTop = next_button.firstElementChild;
      const nextBtnBottom = next_button.lastElementChild;

      buildPrevBtnTimeline();
      buildNextBtnTimeline();

      prev_button.addEventListener("mouseenter", (_) =>
        prevBtnTimeline.play(0)
      );
      prev_button.addEventListener("mouseleave", (_) =>
        prevBtnTimeline.reverse(0)
      );
      next_button.addEventListener("mouseenter", (_) =>
        nextBtnTimeline.play(0)
      );
      next_button.addEventListener("mouseleave", (_) =>
        nextBtnTimeline.reverse(0)
      );

      initCardEvents();

      function buildPrevBtnTimeline() {
        prevBtnTimeline
          .pause()
          .to(
            prevBtnTop,
            0.25,
            {
              rotation: -45,
              width: arrowLength,
              y: "+=2",
              ease: Power2.easeOut,
            },
            "0"
          )
          .to(
            prevBtnBottom,
            0.25,
            {
              rotation: 45,
              width: arrowLength,
              ease: Power2.easeOut,
            },
            "0"
          );
      }

      function buildNextBtnTimeline() {
        nextBtnTimeline
          .pause()
          .to(
            nextBtnTop,
            0.25,
            {
              rotation: -45,
              width: arrowLength,
              y: "-=2",
              ease: Power2.easeOut,
            },
            "0"
          )
          .to(
            nextBtnBottom,
            0.25,
            {
              rotation: 45,
              width: arrowLength,
              ease: Power2.easeOut,
            },
            "0"
          );
      }

      gsap
        .timeline()
        .set(cardsContainerEl.children, {
          "--card-translateY-offset": "100vh",
        })
        .set(cardInfosContainerEl.querySelector(".current_info").children, {
          translateY: "40px",
          autoAlpha: 0,
        })
        .set([prev_button, next_button], {
          pointerEvents: "none",
          autoAlpha: "0",
        });

      images.forEach((image) => {
        imagesLoaded(image, (instance) => {
          if (instance.isComplete) {
            loadedImages++;
            let loadProgress = loadedImages / totalImages;

            gsap.to(loaderEl, {
              duration: 1,
              scaleX: loadProgress,
              backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%`,
            });

            if (totalImages === loadedImages) {
              gsap
                .timeline()
                .to(".loading__wrapper", {
                  duration: 0.8,
                  autoAlpha: 0,
                  pointerEvents: "none",
                },
                "+=1")
                .to(cardsContainerEl.children, {
                  "--card-translateY-offset": "0vh",
                  duration: 0.5,
                })
                .to(document.querySelector(".current_info").children, {
                  //delay: 0.5,
                  duration: 0.4,
                  stagger: 0.1,
                  autoAlpha: 1,
                  translateY: 0,
                })
                .to(
                  [prev_button, next_button],
                  {
                    duration: 0.4,
                    autoAlpha: 1,
                    pointerEvents: "all",
                  },
                  "+=0.4"
                );
              //.call(() => init());
            }
          }
        });
      });
    }
  }, [current, initCardEvents, nextBtnTimeline, prevBtnTimeline]);

  const handlers = useSwipeable({
    onSwipedLeft: () => GoRight(),
    onSwipedRight: () => GoLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  
  if (current.matches("loading") || current.matches("idle")) {
    return (
      
<div id="home" className="content">
    <div className="loading__wrapper">
    <div className="loader">
        <div className="blocks" style={{"--i": 1}} />
        <div className="blocks" style={{"--i": 2}} />
        <div className="blocks" style={{"--i": 3}} />
        <div className="blocks" style={{"--i": 4}} />
        <div className="blocks" style={{"--i": 5}} />
        <div className="blocks" style={{"--i": 6}} />
        <div className="blocks" style={{"--i": 7}} />
        <div className="blocks" style={{"--i": 8}} />
        <div className="blocks" style={{"--i": 9}} />
        <div className="blocks" style={{"--i": 10}} />
        <div className="blocks" style={{"--i": 11}} />
        <div className="blocks" style={{"--i": 12}} />
        <div className="blocks" style={{"--i": 13}} />
        <div className="blocks" style={{"--i": 14}} />
        <div className="blocks" style={{"--i": 15}} />
        <div className="blocks" style={{"--i": 16}} />
        <div className="blocks" style={{"--i": 17}} />
        <div className="blocks" style={{"--i": 18}} />
        <div className="blocks" style={{"--i": 19}} />
        <div className="blocks" style={{"--i": 20}} />
        <h3>Loading</h3>
    </div>
</div>
</div>
    );
  }

  function resetCardTransforms(e) {
    const card = e.currentTarget;
    const currentInfoEl = document.querySelector(".current_info");
    gsap.set(card, {
      "--current-card-rotation-offset": 0,
    });
    gsap.set(currentInfoEl, {
      rotateY: 0,
    });
  }
  function updateCard(e) {
    const card = e.currentTarget;
    const box = card.getBoundingClientRect();
    const centerPosition = {
      x: box.left + box.width / 2,
      y: box.top + box.height / 2,
    };
    let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (40 / Math.PI);
    gsap.set(card, {
      "--current-card-rotation-offset": `${angle}deg`,
    });
    const currentInfoEl = document.querySelector(".current_info");
    gsap.set(currentInfoEl, {
      rotateY: `${angle}deg`,
    });
  }

  function removeCardEvents(card) {
    card.removeEventListener("pointermove", updateCard);
  }

  function GoRight() {
    //console.log("GoRight");
    let n_cards = cardsContainerEl.children.length;
    let currentCardEl = cardsContainerEl.querySelector(".current_card");
    let currentCardEl_id = parseInt(currentCardEl.id);

    let currentBgImageEl = appBgContainerEl.querySelector(".current_image");
    let nextBgImageEl = appBgContainerEl.querySelector(".next_image");

    let currentInfoEl = document.querySelector(".current_info");
    let nextInfoEl = cardInfosContainerEl.querySelector(".next_info");



    function removeClassExcept(selector, val) {
      $(selector).removeClass().addClass(val);
    }

    removeCardEvents(currentCardEl);

    let i;

    for (i = 1; i <= n_cards; i++) {
      removeClassExcept(`#${i}`, "card hidden_card");
      removeClassExcept(`#${i}_info`, "info hidden_info");
      removeClassExcept(`#${i}_bg`, "app__bg__image hidden_image");
    }

    currentBgImageEl.style.zIndex = "-2";

    nextBgImageEl.style.zIndex = "-1";

    if (currentCardEl_id === n_cards) {
      currentCardEl_id = 1;
    } else {
      currentCardEl_id = currentCardEl_id + 1;
    }

    if (currentCardEl_id === 1) {
      previousCardEl_id = n_cards;
      previous_previousCardEl_id = n_cards - 1;
      nextCardEl_id = currentCardEl_id + 1;
      next_nextCardEl_id = currentCardEl_id + 2;
    } else if (currentCardEl_id === 2) {
      previousCardEl_id = 1;
      previous_previousCardEl_id = n_cards;
      nextCardEl_id = currentCardEl_id + 1;
      next_nextCardEl_id = currentCardEl_id + 2;
    } else if (currentCardEl_id === n_cards - 1) {
      previousCardEl_id = currentCardEl_id - 1;
      previous_previousCardEl_id = currentCardEl_id - 2;
      nextCardEl_id = n_cards;
      next_nextCardEl_id = 1;
    } else if (currentCardEl_id === n_cards) {
      previousCardEl_id = currentCardEl_id - 1;
      previous_previousCardEl_id = currentCardEl_id - 2;
      nextCardEl_id = 1;
      next_nextCardEl_id = 2;
    } else {
      previousCardEl_id = currentCardEl_id - 1;
      previous_previousCardEl_id = currentCardEl_id - 2;
      nextCardEl_id = currentCardEl_id + 1;
      next_nextCardEl_id = currentCardEl_id + 2;
    }

    removeClassExcept(
      `#${previous_previousCardEl_id}`,
      "card previous_previous_card"
    );
    removeClassExcept(`#${previousCardEl_id}`, "card previous_card");
    removeClassExcept(`#${currentCardEl_id}`, "card current_card");
    removeClassExcept(`#${nextCardEl_id}`, "card next_card");
    removeClassExcept(`#${next_nextCardEl_id}`, "card next_next_card");

    function swapInfosClass() {
      removeClassExcept(`#${currentCardEl_id}_info`, "info current_info");
      removeClassExcept(`#${previousCardEl_id}_info`, "info previous_info");
      removeClassExcept(`#${nextCardEl_id}_info`, "info next_info");
    }

    removeClassExcept(
      `#${previousCardEl_id}_bg`,
      "app__bg__image previous_image"
    );
    removeClassExcept(
      `#${currentCardEl_id}_bg`,
      "app__bg__image current_image"
    );
    removeClassExcept(`#${nextCardEl_id}_bg`, "app__bg__image next_image");

    const start = () => {
      gsap.timeline().to([prev_button, next_button], {
        duration: 0.2,
        autoAlpha: 0,
        pointerEvents: "none",
      });
    };

    const complete = () => {
      gsap.timeline().to([prev_button, next_button], {
        duration: 0.2,
        autoAlpha: 1,
        pointerEvents: "all",
      });
    };

    gsap
      .timeline()
      .to(
        currentInfoEl.children,
        {
          onStart: start,
          duration: 0.4,
          stagger: 0.1,
          translateY: "-120px",
          autoAlpha: 0,
        },
        "-="
      )
      .call(() => {
        swapInfosClass();
      })
      .call(() => initCardEvents())
      .fromTo(
        nextInfoEl.children,
        {
          autoAlpha: 0,
          translateY: "40px",
        },
        {
          duration: 0.4,
          stagger: 0.1,
          translateY: "0px",
          autoAlpha: 1,
          onComplete: complete,
        }
      );
  }

  function GoLeft() {
    //console.log("GoLeft");
    let n_cards = cardsContainerEl.children.length;
    let currentCardEl = cardsContainerEl.querySelector(".current_card");
    let currentCardEl_id = parseInt(currentCardEl.id);

    let currentBgImageEl = appBgContainerEl.querySelector(".current_image");
    let previousBgImageEl = appBgContainerEl.querySelector(".previous_image");

    let currentInfoEl = document.querySelector(".current_info");
    let previousInfoEl = cardInfosContainerEl.querySelector(".previous_info");



    function removeClassExcept(selector, val) {
      $(selector).removeClass().addClass(val);
    }

    let i;

    for (i = 1; i <= n_cards; i++) {
      removeClassExcept(`#${i}`, "card hidden_card");
      removeClassExcept(`#${i}_info`, "info hidden_info");
      removeClassExcept(`#${i}_bg`, "app__bg__image hidden_image");
    }

    currentBgImageEl.style.zIndex = "-2";

    previousBgImageEl.style.zIndex = "-1";

    if (currentCardEl_id === 1) {
      currentCardEl_id = n_cards;
    } else {
      currentCardEl_id = currentCardEl_id - 1;
    }

    if (currentCardEl_id === 1) {
      previousCardEl_id = n_cards;
      previous_previousCardEl_id = n_cards - 1;
      nextCardEl_id = currentCardEl_id + 1;
      next_nextCardEl_id = currentCardEl_id + 2;
    } else if (currentCardEl_id === 2) {
      previousCardEl_id = 1;
      previous_previousCardEl_id = n_cards;
      nextCardEl_id = currentCardEl_id + 1;
      next_nextCardEl_id = currentCardEl_id + 2;
    } else if (currentCardEl_id === n_cards - 1) {
      previousCardEl_id = currentCardEl_id - 1;
      previous_previousCardEl_id = currentCardEl_id - 2;
      nextCardEl_id = n_cards;
      next_nextCardEl_id = 1;
    } else if (currentCardEl_id === n_cards) {
      previousCardEl_id = currentCardEl_id - 1;
      previous_previousCardEl_id = currentCardEl_id - 2;
      nextCardEl_id = 1;
      next_nextCardEl_id = 2;
    } else {
      previousCardEl_id = currentCardEl_id - 1;
      previous_previousCardEl_id = currentCardEl_id - 2;
      nextCardEl_id = currentCardEl_id + 1;
      next_nextCardEl_id = currentCardEl_id + 2;
    }

    removeClassExcept(
      `#${previous_previousCardEl_id}`,
      "card previous_previous_card"
    );
    removeClassExcept(`#${previousCardEl_id}`, "card previous_card");
    removeClassExcept(`#${currentCardEl_id}`, "card current_card");
    removeClassExcept(`#${nextCardEl_id}`, "card next_card");
    removeClassExcept(`#${next_nextCardEl_id}`, "card next_next_card");

    function swapInfosClass() {
      removeClassExcept(`#${currentCardEl_id}_info`, "info current_info");
      removeClassExcept(`#${previousCardEl_id}_info`, "info previous_info");
      removeClassExcept(`#${nextCardEl_id}_info`, "info next_info");
    }

    removeClassExcept(
      `#${previousCardEl_id}_bg`,
      "app__bg__image previous_image"
    );
    removeClassExcept(
      `#${currentCardEl_id}_bg`,
      "app__bg__image current_image"
    );
    removeClassExcept(`#${nextCardEl_id}_bg`, "app__bg__image next_image");

    //----------------------------------

    const start = () => {
      gsap.timeline().to([prev_button, next_button], {
        duration: 0.2,
        autoAlpha: 0,
        pointerEvents: "none",
      });
    };

    const complete = () => {
      gsap.timeline().to([prev_button, next_button], {
        duration: 0.2,
        autoAlpha: 1,
        pointerEvents: "all",
      });
    };

    gsap
      .timeline()
      .to(
        currentInfoEl.children,
        {
          xonStart: start,
          duration: 0.4,
          stagger: 0.1,
          translateY: "-120px",
          autoAlpha: 0,
        },
        "-="
      )
      .call(() => {
        swapInfosClass();
      })
      .call(() => initCardEvents())
      .fromTo(
        previousInfoEl.children,
        {
          autoAlpha: 0,
          translateY: "40px",
        },
        {
          duration: 0.4,
          stagger: 0.1,
          translateY: "0px",
          autoAlpha: 1,
          onComplete: complete,
        }
      );
  }

  return (

<div {...handlers}>
<div id="home" className="content">
<div className="app">
    <div className="cardList">
    <button
        ref={(el) => (prev_button = el)}
        id="hello"
        className="cardList__btn btn btn--left"
        onClick={GoLeft}
    >
        <span></span>
        <span></span>
    </button>

    {/* --------------------------------------------------------------------------- */}
    <div
        ref={(el) => (cardsContainerEl = el)}
        className="cards__wrapper">
        
    {/* --------------------------------------------------------------------------- */}
    {/* console.log(current.context.jsonFile) */}
        {current.context.jsonFile.map((item, i) => ( 
          i===0 ?
          <div key={i} id={i+1} className="card current_card">
          <div className="card__image">
              <img src={item.img} alt="" />
          </div>
          </div>
          : i=== 1 ?
          <div key={i} id={i+1} className="card next_card">
          <div className="card__image">
              <img src={item.img} alt="" />
          </div>
          </div>
          :i=== 2 ?
          <div key={i} id={i+1} className="card next_next_card">
          <div className="card__image">
              <img src={item.img} alt="" />
          </div>
          </div>
          :i=== (current.context.jsonFile.length -1) ?
          <div key={i} id={i+1} className="card previous_card">
          <div className="card__image">
              <img src={item.img} alt="" />
          </div>
          </div>
          :
          <div key={i} id={i+1} className="card previous_previous_card">
          <div className="card__image">
              <img src={item.img} alt="" />
          </div>
          </div>
          
  ))}
        
        
    {/* --------------------------------------------------------------------------- */}
        </div>
    {/* --------------------------------------------------------------------------- */}
    
      <button
          ref={(el) => (next_button = el)}
          className="cardList__btn btn btn--right"
          onClick={GoRight}
      >
          <span></span>
          <span></span>
      </button>
    </div>
    
    {/* --------------------------------------------------------------------------- */}
    <div className="infoList">
    <div
        ref={(el) => (cardInfosContainerEl = el)}
        className="info__wrapper">
    
    {/* --------------------------------------------------------------------------- */}

    {current.context.jsonFile.map((item, i) => ( 
          i=== 0 ?
            <div key={i} id={`${i + 1}_info`} className="info current_info">
              <h1 className="text name">
                {item.text.title}
              </h1>
              <h4 className="text location">
                {item.text.sub_title}
              </h4>
              <p className="text description">
                {item.text.description}
              </p>
              <a
                className="text btn btn-primary"
                href={item.button}
                role="button"
              >
                <span>
                  Read More
                </span>
              </a>
            </div>
          : i === 1 ?
          <div key={i} id={`${i + 1}_info`} className="info next_info">
            <h1 className="text name">
              {item.text.title}
            </h1>
            <h4 className="text location">
              {item.text.sub_title}
            </h4>
            <p className="text description">
              {item.text.description}
            </p>
            <a
              className="text btn btn-primary"
              href={item.button}
              role="button"
            >
            <span>
              Read More
            </span>
            </a>
          </div>
          :i === (current.context.jsonFile.length -1) ?
          <div key={i} id={`${i + 1}_info`} className="info previous_info">
            <h1 className="text name">
              {item.text.title}
            </h1>
            <h4 className="text location">
              {item.text.sub_title}
            </h4>
            <p className="text description">
              {item.text.description}
            </p>
            <a
              className="text btn btn-primary"
              href={item.button}
              role="button"
            >
            <span>
              Read More
            </span>
            </a>
          </div>
          :
          <div key={i} id={`${i + 1}_info`} className="info hidden_info">
            <h1 className="text name">
              {item.text.title}
            </h1>
            <h4 className="text location">
              {item.text.sub_title}
            </h4>
            <p className="text description">
              {item.text.description}
            </p>
            <a
              className="text btn btn-primary"
              href={item.button}
              role="button"
            >
            <span>
              Read More
            </span>
            </a>
          </div>
          
    ))}
    
    {/* --------------------------------------------------------------------------- */}

    </div>
    </div>
    
    {/* --------------------------------------------------------------------------- */}

    <div className="app__bg" ref={(el) => (appBgContainerEl = el)}>
    {/* --------------------------------------------------------------------------- */}


    {current.context.jsonFile.map((item, i) => ( 
          i===0 ?
            <div key={i} id={`${i + 1}_bg`} className="app__bg__image current_image">
              <img src={item.img} alt="" />
            </div>
          : i=== 1 ?
            <div key={i} id={`${i + 1}_bg`} className="app__bg__image next_image">
              <img src={item.img} alt="" />
            </div>
          :i === (current.context.jsonFile.length -1) ?
          <div key={i} id={`${i + 1}_bg`} className="app__bg__image previous_image">
            <img src={item.img} alt="" />
          </div>
          :
          <div key={i} id={`${i + 1}_bg`} className="app__bg__image hidden_image">
            <img src={item.img} alt="" />
          </div>
          
    ))}
    
    {/* --------------------------------------------------------------------------- */}
    </div>
    {/* --------------------------------------------------------------------------- */}
</div>


<div className="loading__wrapper">
    <div className="loader">
        <div className="blocks" style={{"--i": 1}} />
        <div className="blocks" style={{"--i": 2}} />
        <div className="blocks" style={{"--i": 3}} />
        <div className="blocks" style={{"--i": 4}} />
        <div className="blocks" style={{"--i": 5}} />
        <div className="blocks" style={{"--i": 6}} />
        <div className="blocks" style={{"--i": 7}} />
        <div className="blocks" style={{"--i": 8}} />
        <div className="blocks" style={{"--i": 9}} />
        <div className="blocks" style={{"--i": 10}} />
        <div className="blocks" style={{"--i": 11}} />
        <div className="blocks" style={{"--i": 12}} />
        <div className="blocks" style={{"--i": 13}} />
        <div className="blocks" style={{"--i": 14}} />
        <div className="blocks" style={{"--i": 15}} />
        <div className="blocks" style={{"--i": 16}} />
        <div className="blocks" style={{"--i": 17}} />
        <div className="blocks" style={{"--i": 18}} />
        <div className="blocks" style={{"--i": 19}} />
        <div className="blocks" style={{"--i": 20}} />
        <h3>Loading</h3>
    </div>
</div>
<svg className="icons" style={{ display: "none" }}>
    <symbol
    id="arrow-left"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    >
    <polyline
        points="328 112 184 256 328 400"
        style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "48px",
        }}
    />
    </symbol>
    <symbol
    id="arrow-right"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    >
    <polyline
        points="184 112 328 256 184 400"
        style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "48px",
        }}
    />
    </symbol>
</svg>
<div className="support">
    <a
    href="https://www.facebook.com/Pangea-Petroleum-Services-110925800570010"
    target="_blank" rel="noreferrer"
    >
    <i className="fab fa-facebook-square" />
    </a>
    <a
    href="https://www.linkedin.com/company/70459150/admin/"
    target="_blank" rel="noreferrer"
    >
    <i className="fab fa-linkedin" />
    </a>
</div>
</div>
</div>
  );
};
export default Home;
