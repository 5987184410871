import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";

import $ from "jquery";

import { gsap } from "gsap";
import "./work_Gallery_1.css";

$("#gallery_1 div").css({
  width:"100%",
  height:"91%",
  maxHeight:"880px",
  overflow:"hidden",
  background:"antiquewhite",
  position: 'absolute',
});

export default function WorkGallery1() {

  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [value,setValue] = useState();


  useEffect(() => {
    $("body").removeAttr("style");

    setValue({});
  }, []);
  useEffect(() => {
  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

    const Images = [];
    var currentImg = undefined,
      currentImgProps = { x: 0, y: 0 },
      isZooming = false,
      column = -1,
      mouse = { x: 0, y: 0 },
      delayedPlay,
      y = -1,
      totalPhoto = 28,
      photohight = 20,
      Width = window.innerWidth,
      ColumnWidth = 200,
      betweenColumn = 12.5,
      nColumn = Math.floor(Width / (ColumnWidth + 5)),
      margin = (Width - (nColumn * ColumnWidth) - ((nColumn - 1) * betweenColumn))/2,
      nPhoto = Math.ceil(totalPhoto / nColumn),
      ColHeight = nPhoto * 343.75 - 575,
      firColumn = -100 + margin;
      
/*     console.log(`Width = ${Width}`);
    console.log(`margin = ${margin}`); */

    $(".mainBoxes").empty();
      for (var i = 0; i < totalPhoto; i++) {
        if (y === 11) {
          y = -1;
        }
        y++;
        if (i % nPhoto === 0) column++;

        var b = document.createElement("div");
        $(".mainBoxes").append(b);

        gsap.set(b, {
          attr: { id: "b" + i, class: "photoBox pb-col" + column },
          backgroundImage: "url(https://assets.codepen.io/721952/" + y + ".jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
          x: [firColumn, firColumn + 212.5, firColumn + 425, firColumn + 637.5, firColumn + 850, firColumn + 1062.5, firColumn + 1275][column],
          //x:[60,280,500][column],
          width: 400,
          height: 640,
          borderRadius: 20,
          scale: 0.5,
          zIndex: 1,
        });

        b.tl = gsap
          .timeline({ paused: true, repeat: -1 })
          .fromTo(
            b,
            {
              y: [-575, ColHeight, ColHeight, -575, ColHeight, ColHeight, -575][
                column
              ],
              rotation: -0.05,
            },
            {
              duration: [40, 35, 26, 50, 30, 20, 30][column],
              y: [ColHeight, -575, -575, ColHeight, -575, -575, ColHeight][
                column
              ],
              rotation: 0.05,
              ease: "none",
            }
          )
          .progress((i % nPhoto) / nPhoto);
      }

      function pauseBoxes(b) {
        var classStr = "pb-col0";
        if ($(b).hasClass("pb-col1")) classStr = "pb-col1";
        if ($(b).hasClass("pb-col2")) classStr = "pb-col2";
        if ($(b).hasClass("pb-col3")) classStr = "pb-col3";
        if ($(b).hasClass("pb-col4")) classStr = "pb-col4";
        if ($(b).hasClass("pb-col5")) classStr = "pb-col5";
        if ($(b).hasClass("pb-col6")) classStr = "pb-col6";
        for (var i = 0; i < $(".mainBoxes").children().length; i++) {
          var b = $(".mainBoxes").children()[i];
          if ($(b).hasClass(classStr))
            gsap.to(b.tl, { timeScale: 0, ease: "sine" });
        }
      }

      function playBoxes() {
        for (var i = 0; i < $(".mainBoxes").children().length; i++) {
          var tl = $(".mainBoxes").children()[i].tl;
          tl.play();
          gsap.to(tl, {
            duration: 0.4,
              timeScale: 1,
              ease: "sine.in",
            overwrite: true,
          });
        }
      }
      //console.log("init");
      /*     console.log(`Width = ${Width}`);
      console.log(`margin = ${margin}`);
      console.log(`nColumn = ${nColumn}`);
      console.log(`ColHeight = ${ColHeight}`);
      console.log(`nPhoto = ${nPhoto}`); */

      var _tl = gsap
        .timeline({ onStart: playBoxes })
        //var _tl = gsap.timeline({onStart:playBoxes})
        .set(".main", { perspective: 800 })
        .set(".photoBox", { opacity: 1, cursor: "pointer" })
        .set(".mainBoxes", {
          left: "0%",
          xPercent: 0,
          width: "100%",
          height: "100%",
        })
        //.set('.mainBoxes',   {left:'75%', xPercent:-50, width:1200, rotationX:14, rotationY:-15, rotationZ:10})
        .set(".mainClose", {
          autoAlpha: 0,
          width: 60,
          height: 60,
          left: -30,
          top: -31,
          pointerEvents: "none",
        })
        .fromTo(
          ".main",
          { autoAlpha: 0 },
          { duration: 0.6, ease: "power2.inOut", autoAlpha: 1 },
          0.2
        );

      $(".photoBox").on("mouseenter", function (e) {
        //console.log($(e.currentTarget).hasClass("pb-col0"));
        if (currentImg) return;
        if (delayedPlay) delayedPlay.kill();
        pauseBoxes(e.currentTarget);
        var _t = e.currentTarget;
        gsap.to(".photoBox", {
          duration: 0.2,
          overwrite: "auto",
          opacity: function (i, t) {
            return t === _t ? 1 : 0.33;
          },
        });
        gsap.fromTo(
          _t,
          { zIndex: 100, scale: 0.5 },
          { duration: 0.2, scale: 0.62, overwrite: "auto", ease: "power3" }
        );
      });

      $(".photoBox").on("mouseleave", function (e) {
        if (currentImg) return;
        var _t = e.currentTarget;

        //    if (gsap.getProperty(_t, 'scale')>0.62) delayedPlay = gsap.delayedCall(0.3); // to avoid jump, add delay when mouseout occurs as big image scales back down (not 100% reliable because the scale value sometimes evaluates too late)

        if (gsap.getProperty(_t, "scale") > 0.62)
          delayedPlay = gsap.delayedCall(0.3, playBoxes);
        // to avoid jump, add delay when mouseout occurs as big image scales back down (not 100% reliable because the scale value sometimes evaluates too late)
        else playBoxes();

        gsap
          .timeline()
          .set(_t, { zIndex: 1 })
          .to(
            _t,
            { duration: 0.3, scale: 0.5, overwrite: "auto", ease: "expo" },
            0
          )
          .to(
            ".photoBox",
            { duration: 0.5, opacity: 1, ease: "power2.inOut" },
            0
          );
      });

      $(".photoBox").on("click", function (e) {
        if (!isZooming) {
          //only tween if photoBox isn't currently zooming

          isZooming = true;
          gsap.delayedCall(0.8, function () {
            isZooming = false;
          });

          if (currentImg) {
            gsap
              .timeline({ defaults: { ease: "expo.inOut" } })
              .to(
                ".mainClose",
                { duration: 0.1, autoAlpha: 0, overwrite: true },
                0
              )
              .set(
                ".mainBoxes",
                {
                  left: "0%",
                  scale: 1.0,
                  xPercent: 0,
                  width: "100%",
                  overwrite: true,
                },
                0
              )
              //.to('.mainBoxes',   {duration:0.5, scale:1, left:'75%', width:1200, rotationX:14, rotationY:-15, rotationZ:10, overwrite:true}, 0)
              .to(
                ".photoBox",
                { duration: 0.6, opacity: 1, ease: "power4.inOut" },
                0
              )
              .to(
                currentImg,
                {
                  duration: 0.6,
                  width: 400,
                  height: 640,
                  borderRadius: 20,
                  x: currentImgProps.x,
                  y: currentImgProps.y,
                  scale: 0.5,
                  rotation: 0,
                  zIndex: 1,
                },
                0
              );
            // .add(playBoxes, 0.8)
            currentImg = undefined;
          } else {
            pauseBoxes(e.currentTarget);

            currentImg = e.currentTarget;
            currentImgProps.x = gsap.getProperty(currentImg, "x");
            currentImgProps.y = gsap.getProperty(currentImg, "y");

            gsap
              .timeline({ defaults: { duration: 0.6, ease: "expo.inOut" } })
              .set(currentImg, { zIndex: 100 })
              .fromTo(
                ".mainClose",
                { x: mouse.x, y: mouse.y, background: "rgba(0,0,0,0)" },
                { autoAlpha: 1, duration: 0.3, ease: "power3.inOut" },
                0
              )
              .to(".photoBox", { opacity: 0 }, 0)
              .to(
                currentImg,
                {
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                  x: 0,
                  top: 0,
                  y: 0,
                  scale: 1,
                  opacity: 1,
                },
                0
              )
              .to(
                ".mainBoxes",
                {
                  duration: 0.5,
                  left: "2%",
                  width: "96%",
                  rotationX: 0,
                  rotationY: 0,
                  rotationZ: 0,
                },
                0.15
              )
              .to(
                ".mainBoxes",
                { duration: 5, scale: 1.06, rotation: 0.05, ease: "none" },
                0.65
              );
          }
        }
      });

      if (!!("ontouchstart" in window)) {
        //console.log("touch device!");
        mouse.x = window.innerWidth - 50;
        mouse.y = 60;
      } else {
        window.addEventListener("mousemove", function (e) {
          //console.log(`mouse.x = ${e.x} | mouse.y = ${e.y}`)
          mouse.x = e.x;
          mouse.y = e.layerY;
          if (currentImg)
            gsap.to(".mainClose", {
              duration: .5,
              x: mouse.x,
              y: mouse.y,
              overwrite: "auto",
            });
        });
      }
    
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)

    }
  });
  


  const gallery_1_style = {
    width:"100%",
    height:"91%",
    maxHeight:"880px",
    overflow:"hidden",
    backgroundColor: "antiquewhite",
    position:"absolute"
  };
  
  return (
    <div>
    <div id="gallery_1"
      className="content">
        <div style={gallery_1_style}  className="main">
          <div className="mainBoxes fs" />
          <div className="mainClose">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="100%"
              height="100%"
              fill="none"
            >
              <circle cx={30} cy={30} r={30} fill="#000" opacity="0.4" />
              <path
                d="M15,16L45,46 M45,16L15,46"
                stroke="#000"
                strokeWidth="3.5"
                opacity="0.5"
              />
              <path
                d="M15,15L45,45 M45,15L15,45"
                stroke="#fff"
                strokeWidth={2}
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );

}
