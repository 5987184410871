
import React, { useEffect, useRef, useState } from "react";
import { gsap, Linear } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import "./draft_4.css";

function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}
export default function Draft_4() {

  useTitle("PPS - Draft_4")

  
  useEffect(() => {
    
    
    gsap
    .timeline()
    .to('#draft_4 p', { rotationX: 360, rotationY: 360, rotationZ: 360, repeat: -1 , duration: 10, ease: Linear.easeNone })

  });

  return (
    <div>
      <div id="draft_4" className="content">
        <p>Draft 4</p>
        </div>
    </div>
  );
}
