import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import "../src/css/nicepage.css";
import NavBar from "./pages/navbar_footer/navbar_footer";
import Home from "./pages/home/home";
import Work_gallery from "./pages/work_Gallery/work_Gallery";
import Contact_us from "./pages/contact_us/contact_us";
import Service_1 from "./pages/our_services/service_1/service_1";
import Service_2 from "./pages/our_services/service_2/service_2";
import Service_3 from "./pages/our_services/service_3/service_3";
import Qhse_policy from "./pages/qhse/qhse_policy/qhse_policy";
import Draft_2 from "./pages/qhse/draft_2/draft_2";
import Draft_3 from "./pages/qhse/draft_3/draft_3";
import Draft_4 from "./pages/qhse/draft_4/draft_4";
import Draft_5 from "./pages/qhse/draft_5/draft_5";
import About from "./pages/about/about";
import paths from "./paths";

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          {/* <Loader /> */}
      <Switch>
          <Route exact path={paths.Home} component={Home} />
          <Route exact path={paths.Work_gallery} component={Work_gallery} />
          <Route path={paths.Contact_us} component={Contact_us} />
          <Route path={paths.Service_1} component={Service_1} />
          <Route path={paths.Service_2} component={Service_2} />
          <Route path={paths.Service_3} component={Service_3} />
          <Route path={paths.Qhse_policy} component={Qhse_policy} />
          <Route path={paths.Draft_2} component={Draft_2} />
          <Route path={paths.Draft_3} component={Draft_3} />
          <Route path={paths.Draft_4} component={Draft_4} />
          <Route path={paths.Draft_5} component={Draft_5} />
          <Route path={paths.About} component={About} />
          <Route exact path="*">
            <Redirect to={paths.Home} /> 
          </Route>
        </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;