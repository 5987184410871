import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Location from "./getLocation";
import $ from "jquery";
import "./navbar_footer.css";
import LogoPPS from "../../img/Logo00-300x150.png";
import paths from "../../paths";

//import * from './java_1.js';
//import './java_1.js'

let old_count = 0;
let count = 0;
let current_page;
//var w = window.innerWidth;
//var h = window.innerHeight;
let navbar_button_open = "no";
let open_1_a = 0;
let open_2_a = 0;
let num_li = 6;
let open_1;
let open_2;
let navbarHeight;
let navbarDropdownHeight;
let navbarDropdownHeight_1;
let navbarDropdownHeight_2;
let xxx = window.matchMedia("(max-width: 990px)");
let r = document.querySelector(":root");
const currentYear = new Date().getFullYear();

function navbar_button() {
  
  open_1 = "no";
  open_2 = "no";

  if (xxx.matches) {
    //$("#navbar_button").toggleClass("navbar_button_open");
    $(".animated-icon1").toggleClass("open");
    $(".hori2-selector .right").toggleClass("changed");
    $(".hori2-selector .left").toggleClass("changed");
    $(".hori-selector .right").toggleClass("open");

    $("#navbarSupportedContent").removeClass("show");
    $(".content").addClass("navbar_open");
    //$("#navbarSupportedContent").toggleClass('show');

    navbarHeight = 120 + $("#navbarSupportedContent").outerHeight(true);
    if (navbar_button_open === "yes") {
      $(".content").removeClass("navbar_open");
      navbar_button_open = "no";
      Variable_set("--navbar_height", "62.4px");
      var y = document.documentElement.style.getPropertyValue(
        "--navbar_background_color_0"
      );
      Variable_set("--navbar_background_color", y);
    } else {
      $("#navbarSupportedContent").addClass("show");
      navbar_button_open = "yes";
      Variable_set("--navbar_height", navbarHeight + "px");
      var y = document.documentElement.style.getPropertyValue(
        "--body_background_color"
      );
      Variable_set("--navbar_background_color", "#ffffff");
    }
  }
}

function Variable_set(variable, value) {
  r.style.setProperty(variable, value);
}

if ($(document).height() > $(window).height()) {
  console.log("this is:", this);
}

/* $(window).on("resize", function () {
  setTimeout(function () {
    Test1();
  });
}); */

function Test1() {
  let location = document.getElementById("location");
  location = location.innerText.split("/");
  location = location[location.length - 1];

  //console.log("location = " + location);

  if (location === "") {
    $("#nav-item_1").addClass("active");
  } else if (location === "work_gallery") {
    $("#nav-item_2").addClass("active");
  } else if (location === "contact_us") {
    $("#nav-item_3").addClass("active");
  } else if (location === "about") {
    $("#nav-item_4").addClass("active");
    $("#navbarSupportedContent").addClass("overflow-hide");
  } else if (
    location === "service_1" ||
    location === "service_2" ||
    location === "service_3"
  ) {
    $("#dropdown_1").addClass("active");
  } else if (
    location === "qhse_policy" ||
    location === "draft_2" ||
    location === "draft_3" ||
    location === "draft_4" ||
    location === "draft_5"
  ) {
    $("#dropdown_2").addClass("active");
  } else {
    $("#nav-item_1").addClass("active");
  }

  var tabsNewAnim = $("#navbarSupportedContent");
  //var selectorNewAnim = $('#navbarSupportedContent').find('li').length;
  var activeItemNewAnim = tabsNewAnim.find(".active");
  let elementId = document.getElementById("#nav-item_1");
  var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
  var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
  var itemPosNewAnimTop = activeItemNewAnim.position();
  var itemPosNewAnimLeft = activeItemNewAnim.position();

  if (!xxx.matches) {
    $("#navbarSupportedContent ul li div").removeClass("slideDown");
    open_1 = "no";
    open_2 = "no";

    $(".hori-selector").css({
      top: "0px",
      left: itemPosNewAnimLeft.left + "px",
      height: "52.4px",
      width: activeWidthNewAnimWidth + "px",
    });
  } else {
    $(".hori-selector").css({
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height: activeWidthNewAnimHeight + "px",
      width: "100%",
    });
  }

  $("body").removeClass("load");
}
function Test2() {
  var tabsNewAnim = $("#navbarSupportedContent");
  //var selectorNewAnim = $('#navbarSupportedContent').find('li').length;
  //var activeItemNewAnim = document.getElementsByClassName(".active");
  //var selectorNewAnim = $('#navbarSupportedContent').find('li').length;
  /*   var testElements = document.getElementsByClassName('test');
var testDivs = Array.prototype.filter.call(testElements, function(testElement) {
  return testElement.nodeName === 'DIV';
}); */
  var activeItemNewAnim = document.getElementsByClassName("nav-item active")[0];

  //console.log(activeItemNewAnim);

  //activeItemNewAnim = ReactDOM.findDOMNode().getElementsByClassName('active');
  //let elementId = document.getElementById("#nav-item_1");
  var activeWidthNewAnimHeight = activeItemNewAnim.offsetHeight;
  var activeWidthNewAnimWidth = activeItemNewAnim.offsetWidth;
  var itemPosNewAnimTop = activeItemNewAnim.offsetTop;
  var itemPosNewAnimLeft = activeItemNewAnim.offsetLeft;

  if (!xxx.matches) {
    navbar_button_open = "no";
    Variable_set("--navbar_height", "62.4px");
    var y = document.documentElement.style.getPropertyValue(
      "--navbar_background_color_0"
    );
    Variable_set("--navbar_background_color", y);
    $(".animated-icon1").removeClass("open");
    $("#navbarSupportedContent").removeClass("show");
    $("#navbarSupportedContent ul li div").removeClass("slideDown");
    //open_1 = "no";
    //open_2 = "no";

    $(".hori-selector").css({
      top: "0px",
      left: itemPosNewAnimLeft + "px",
      height: "52.4px",
      width: activeWidthNewAnimWidth + "px",
    });
  } else {
    $(".hori-selector").css({
      top: itemPosNewAnimTop + "px",
      left: itemPosNewAnimLeft + "px",
      height: activeWidthNewAnimHeight + "px",
      width: "100%",
    });
  }

  $("body").removeClass("load");
}
/* function Headline() {
  console.log("no");
} */
class NavBar extends Component {
  componentWillMount() {
    // axios commands to get navigation bar setting it newpage state
  }
  componentDidMount() {
    Test1();
    var intervalId = setInterval(function () {
      Test2();
    }, 200);
    //clearInterval(intervalId);
  }

  /*   constructor(props) {
    super(props);
    this.NavItem = this.NavItem.bind(this);
  } */

  Body() {
    //console.log("old_count " + old_count);
    //console.log("count " + count);
    if (old_count === count) {
      $("#dropdown_1 > div").removeClass("show");
      $("#dropdown_2 > div").removeClass("show");
    } else {
      old_count = count;
    }
  }

  NavItem = (event) => {
    $("#navbarSupportedContent").addClass("overflow-hide");

    $("html").addClass("overflow-hide");

    let brandId = document.getElementById("navbar_brand");
    let brandWidth = brandId.getBoundingClientRect().width;
    let elementId = document.getElementById(event.currentTarget.id);
    let rect = elementId.getBoundingClientRect();

    navbarDropdownHeight = $("#dropdown_1 > div").outerHeight(true);

    let activeWidthNewAnimHeight;
    let activeWidthNewAnimWidth = rect.width;
    //var itemPosNewAnimTop = $(this).position();
    let itemPosNewAnimTop_4;
    let itemPosNewAnimLeft = rect.x - brandWidth;

    //console.log(rect);

    $("#navbarSupportedContent ul li").removeClass("active");
    $(elementId).addClass("active");

    if (!xxx.matches) {
      //variable_set('--navbar_padding_right', '0px');
      Variable_set("--navbar_margin_top", "0px");
      //$("#navbarSupportedContent").css({"margin-top": "0px"});

      open_1 = "no";
      open_2 = "no";

      $(".hori-selector").css({
        top: "0px",
        left: itemPosNewAnimLeft + "px",
        height: "52.4px",
        width: activeWidthNewAnimWidth + "px",
      });
    } else {
      open_1 = "no";
      open_2 = "no";

      navbarHeight = activeWidthNewAnimHeight * num_li + 60;
      itemPosNewAnimTop_4 = activeWidthNewAnimHeight * (num_li - 6);
      Variable_set("--navbar_height", navbarHeight + "px");

      $(".hori-selector").css({
        top: itemPosNewAnimTop_4 + "px",
        left: itemPosNewAnimLeft.left + "px",
        height: activeWidthNewAnimHeight + "px",
        width: "100%",
      });
    }

    $("html").removeClass("overflow-hide");
    $("#dropdown_1 > div").removeClass("show");
    $("#dropdown_2 > div").removeClass("show");

    if (xxx.matches) {
      $(".content").removeClass("navbar_open");
      navbar_button_open = "no";
      Variable_set("--navbar_height", "62.4px");
      var y = document.documentElement.style.getPropertyValue(
        "--navbar_background_color_0"
      );
      Variable_set("--navbar_background_color", y);
      $(".animated-icon1").removeClass("open");
      $("#navbarSupportedContent").removeClass("show");
    }
  };

  // --------------------------------------------------------
  // -----------------"#navbarDropdown_1"--------------------
  // --------------------------------------------------------

  NavDropdown_1 = (event) => {
    $("#navbarSupportedContent").removeClass("overflow-hide");
    old_count = count;
    count += 1;

    $("#dropdown_2 > div").removeClass("show");

    /*     $("#dropdown_1 > div").removeClass("show");
    $("#dropdown_2 > div").removeClass("show"); */
    $(event.currentTarget.children[1]).toggleClass("show");
    Variable_set("--navbar_margin_top", "0px");
    //$("#navbarSupportedContent").css({"margin-top": "0px"});
    //$("body > nav > div.collapse.navbar-collapse").removeClass("tttttt");

    open_2 = "no";

    let elementId = document.getElementById(event.currentTarget.id);
    console.log(elementId);
    //console.log(event.currentTarget.id);

    navbarDropdownHeight = $(event.currentTarget.children[1]).outerHeight(true);
    if (!xxx.matches) {
      Variable_set("--navbar_padding_right", "0px");
      //$("#navbarSupportedContent").css({"margin-top": "0px"});
      /*       if (open_1 === "yes") {
        Variable_set("--navbar_margin_top", "0px");
        //$("#navbarSupportedContent").css({"margin-top": "0px"});
        //$("body > nav > div.collapse.navbar-collapse").removeClass("tttttt");
        open_1 = "no";
        open_2 = "no";
      } else { */
      Variable_set("--navbar_margin_top", navbarDropdownHeight + "px");
      //$("#navbarSupportedContent").css({"margin-top": navbarDropdownHeight + "px"});
      //$("#navbarSupportedContent").addClass("tttttt");

      open_1 = "yes";
      open_2 = "no";

      $("#navbarSupportedContent ul li").removeClass("active");

      $(elementId).addClass("active");
      var activeWidthNewAnimHeight = $(elementId).innerHeight();
      var activeWidthNewAnimWidth = $(elementId).innerWidth();
      var itemPosNewAnimTop = $(elementId).position();
      var itemPosNewAnimLeft = $(elementId).position();

      $(".hori-selector").css({
        top: "0px",
        left: itemPosNewAnimLeft.left + "px",
        height: "52.4px",
        width: activeWidthNewAnimWidth + "px",
      });
      //}
    } else {
      navbarDropdownHeight = $("#dropdown_1 > div").outerHeight(true);
      navbarHeight = $("#navbarSupportedContent").outerHeight(true);

      if (open_1 === "yes") {
        $("#dropdown_1").addClass("change");
        Variable_set(
          "--navbar_height",
          navbarHeight - navbarDropdownHeight + 60 + "px"
        );
        //$(".navbar-mainbg").animate({height: navbarHeight - navbarDropdownHeight + 60 + "px"});
        open_1 = "no";

        activeWidthNewAnimHeight = $(elementId).innerHeight();
        activeWidthNewAnimWidth = $(elementId).innerWidth();
        itemPosNewAnimTop = $(elementId).position();
        itemPosNewAnimLeft = $(elementId).position();

        activeWidthNewAnimHeight =
          activeWidthNewAnimHeight - navbarDropdownHeight;

        //Variable_set('--navbar_height', navbarHeight - navbarDropdownHeight + 60 + 'px');
        //$(".navbar-mainbg").animate({height: navbarHeight - navbarDropdownHeight + 60 + "px"});

        $(".hori-selector").css({
          top: itemPosNewAnimTop.top + "px",
          left: itemPosNewAnimLeft.left + "px",
          height: activeWidthNewAnimHeight + "px",
          width: "100%",
        });

        $(".content").removeClass("navbar_open");
        navbar_button_open = "no";
        Variable_set("--navbar_height", "62.4px");
        var y = document.documentElement.style.getPropertyValue(
          "--navbar_background_color_0"
        );
        Variable_set("--navbar_background_color", y);
        $(".animated-icon1").removeClass("open");
        $("#navbarSupportedContent").removeClass("show");
      } else {
        if (open_2 === "yes") {
          navbarDropdownHeight = $("#dropdown_2 > div").outerHeight(true);
          navbarHeight = navbarHeight - navbarDropdownHeight;
          Variable_set("--navbar_height", navbarHeight + "px");
          //$(".navbar-mainbg").animate({height: navbarHeight + "px"});
        }

        $("#dropdown_1").removeClass("change");
        navbarDropdownHeight = $("#dropdown_1 > div").outerHeight(true);
        open_1_a = open_1_a + 1;
        open_1 = "yes";
        open_2 = "no";
        $("#navbarSupportedContent ul li").removeClass("active");
        $("#dropdown_1").addClass("active");
        activeWidthNewAnimHeight = $("#dropdown_1").innerHeight();
        activeWidthNewAnimWidth = $("#dropdown_1").innerWidth();
        itemPosNewAnimTop = $("#dropdown_1").position();
        itemPosNewAnimLeft = $("#dropdown_1").position();

        activeWidthNewAnimHeight =
          activeWidthNewAnimHeight + navbarDropdownHeight;

        Variable_set("--navbar_height", navbarHeight + 120 + "px");
        //$(".navbar-mainbg").animate({height: navbarHeight + navbarDropdownHeight + 60 + "px"});

        $(".hori-selector").css({
          top: itemPosNewAnimTop.top + "px",
          left: itemPosNewAnimLeft.left + "px",
          height: activeWidthNewAnimHeight + "px",
          width: "100%",
        });
      }
    }
  };

  NavDropdown_2 = (event) => {
    $("#navbarSupportedContent").removeClass("overflow-hide");
    old_count = count;
    count += 1;

    $("#dropdown_1 > div").removeClass("show");

    $(event.currentTarget.children[1]).toggleClass("show");
    Variable_set("--navbar_margin_top", "0px");

    open_1 = "no";

    let elementId = document.getElementById(event.currentTarget.id);
    console.log(elementId);

    navbarDropdownHeight = $(event.currentTarget.children[1]).outerHeight(true);
    if (!xxx.matches) {
      Variable_set("--navbar_padding_right", "0px");
      //$("#navbarSupportedContent").css({"margin-top": "0px"});

      Variable_set("--navbar_margin_top", navbarDropdownHeight + "px");
      //$("#navbarSupportedContent").css({"margin-top": navbarDropdownHeight + "px"});
      //$("#navbarSupportedContent").addClass("tttttt");

      open_2 = "yes";
      open_1 = "no";

      $("#navbarSupportedContent ul li").removeClass("active");
      $("#dropdown_2").addClass("active");
      var activeWidthNewAnimHeight = $("#dropdown_2").innerHeight();
      var activeWidthNewAnimWidth = $("#dropdown_2").innerWidth();
      var itemPosNewAnimTop = $("#dropdown_2").position();
      var itemPosNewAnimLeft = $("#dropdown_2").position();

      $(".hori-selector").css({
        top: "0px",
        left: itemPosNewAnimLeft.left + "px",
        height: "52.4px",
        width: activeWidthNewAnimWidth + "px",
      });
    } else {
      navbarDropdownHeight = $("#dropdown_2 > div").outerHeight(true);
      navbarHeight = $("#navbarSupportedContent").outerHeight(true);

      if (open_2 === "yes") {
        $("#dropdown_2").addClass("change");
        Variable_set(
          "--navbar_height",
          navbarHeight - navbarDropdownHeight + 60 + "px"
        );
        //$(".navbar-mainbg").animate({height: navbarHeight - navbarDropdownHeight + 60 + "px"});
        open_2 = "no";

        activeWidthNewAnimHeight = $("#dropdown_2").innerHeight();
        activeWidthNewAnimWidth = $("#dropdown_2").innerWidth();
        itemPosNewAnimTop = $("#dropdown_2").position();
        itemPosNewAnimLeft = $("#dropdown_2").position();

        activeWidthNewAnimHeight =
          activeWidthNewAnimHeight - navbarDropdownHeight;

        //Variable_set('--navbar_height', navbarHeight - navbarDropdownHeight + 60 + 'px');
        //$(".navbar-mainbg").animate({height: navbarHeight - navbarDropdownHeight + 60 + "px"});

        $(".hori-selector").css({
          top: itemPosNewAnimTop.top + "px",
          left: itemPosNewAnimLeft.left + "px",
          height: activeWidthNewAnimHeight + "px",
          width: "100%",
        });

        $(".content").removeClass("navbar_open");
        navbar_button_open = "no";
        Variable_set("--navbar_height", "62.4px");
        var y = document.documentElement.style.getPropertyValue(
          "--navbar_background_color_0"
        );
        Variable_set("--navbar_background_color", y);
        $(".animated-icon1").removeClass("open");
        $("#navbarSupportedContent").removeClass("show");
      } else {
        $("#dropdown_2").removeClass("change");
        navbarDropdownHeight_1 = 0;
        if (open_1 === "yes") {
          navbarDropdownHeight_1 = $("#dropdown_1 > div").outerHeight(true);
          navbarHeight = navbarHeight - navbarDropdownHeight_1;
          Variable_set("--navbar_height", navbarHeight + "px");
          //$(".navbar-mainbg").animate({height: navbarHeight + "px"});
        }

        navbarDropdownHeight_2 = $("#dropdown_2 > div").outerHeight(true);
        open_2_a = open_2_a + 1;
        open_1 = "no";
        open_2 = "yes";

        $("#navbarSupportedContent ul li").removeClass("active");
        $("#dropdown_2").addClass("active");
        var activeWidthNewAnimHeight = $("#dropdown_2").innerHeight();
        var activeWidthNewAnimWidth = $("#dropdown_2").innerWidth();
        var itemPosNewAnimTop = $("#dropdown_2").position();
        var itemPosNewAnimLeft = $("#dropdown_2").position();

        activeWidthNewAnimHeight =
          activeWidthNewAnimHeight + navbarDropdownHeight;

        Variable_set("--navbar_height", navbarHeight + 120 + "px");
        //$(".navbar-mainbg").animate({height: navbarHeight + navbarDropdownHeight + 60 + "px"});

        $(".hori-selector").css({
          top: itemPosNewAnimTop.top - navbarDropdownHeight_1 + "px",
          left: itemPosNewAnimLeft.left + "px",
          height: activeWidthNewAnimHeight + "px",
          width: "100%",
        });
      }
    }
  };
  // --------------------------------------------------------
  // ---------------"End #navbarDropdown_1"------------------
  // --------------------------------------------------------

  render() {
    return (
      <div id="navbar_footer" onClick={this.Body}>
        <header hidden id="location">
          <Location />
        </header>
        <nav
          className="navbar navbar-expand-lg navbar-mainbg"
          style={{ zIndex: 30 }}
        >
          <div
            className="hori2-selector"
            style={{
              top: "0px",
              left: "50px",
              height: "52.4px",
              width: "155.95px",
            }}
          >
            <div className="left" />
            <div className="right" />
          </div>

          <a
            className="navbar-brand"
            id="navbar_brand"
            // href="/"
          >
          <Link className="nav-link" id="navItem_1" to={paths.Home}>
          </Link>
            <img
              className="logo"
              id="pangea-logo"
              src={LogoPPS}
              alt="pangea Logo"
              draggable="false"
              height={50}
            />
          </a>
          <button
            onClick={navbar_button}
            id="navbar_button"
            className="navbar-toggler first-button"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div id="navbar_button" className="animated-icon1">
              <span />
              <span />
              <span />
            </div>
          </button>
          <div
            className="collapse navbar-collapse justify-content-md-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              <div
                className="hori-selector"
                style={{
                  top: "0px",
                  left: "300px",
                  height: "52.4px",
                  width: "155.95px",
                }}
              >
                <div className="left" id="left" />
                <div className="right" id="right" />
              </div>
              <li onClick={this.NavItem} className="nav-item" id="nav-item_1">
                <Link className="nav-link" id="navItem_1" to={paths.Home}>
                  <i className="fas fa-home" />
                  Home
                </Link>
              </li>
              <li onClick={this.NavItem} className="nav-item" id="nav-item_4">
                <NavLink className="nav-link" id="nav-item_4" to={paths.About}>
                  <i className="fas fa-address-card" />
                  About Us
                </NavLink>
              </li>
              {/*               <li
                onClick={this.NavDropdown_1}
                className="nav-item dropdown"
                id="dropdown_1"
              >
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown_1"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-list-alt" />
                  Our Services
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown_1"
                >
                  <NavLink
                    className="dropdown-item"
                    id="service_1_p"
                    to={paths.Service_1}
                  >
                    Service 1
                  </NavLink>
                  <NavLink
                    className="dropdown-item"
                    id="service_2_p"
                    to={paths.Service_2}
                  >
                    Service 2
                  </NavLink>
                  <div className="dropdown-divider" />
                  <NavLink
                    className="dropdown-item"
                    id="service_3_p"
                    to={paths.Service_3}
                  >
                    Service 3
                  </NavLink>
                </div>
              </li> */}
              <li
                onClick={this.NavDropdown_2}
                className="nav-item dropdown"
                id="dropdown_2"
              >
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown_2"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src="" className="qhse" />
                  QHSE
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown_2"
                >
                  <NavLink
                    className="dropdown-item"
                    id="qhse_p"
                    to={paths.Qhse_policy}
                  >
                    QHSE Policy
                  </NavLink>
                  {/*                   <NavLink className="dropdown-item" id="draft_2" to={paths.Draft_2}>
                    Draft 2
                  </NavLink>
                  <div className="dropdown-divider" />
                  <NavLink className="dropdown-item" id="draft_3" to={paths.Draft_3}>
                    Draft 3
                  </NavLink>
                  <div className="dropdown-divider" />
                  <NavLink className="dropdown-item" id="draft_4" to={paths.Draft_4}>
                    Draft 4
                  </NavLink>
                  <div className="dropdown-divider" />
                  <NavLink className="dropdown-item" id="draft_5" to={paths.Draft_5}>
                    Draft 5
                  </NavLink> */}
                </div>
              </li>
              {/*               <li onClick={this.NavItem} className="nav-item" id="nav-item_2">
                <Link className="nav-link" id="navItem_2" to={paths.Work_gallery}>
                  <i className="fas fa-images" />
                  Our Work Gallery
                </Link>
              </li> */}
              <li onClick={this.NavItem} className="nav-item" id="nav-item_3">
                <NavLink
                  className="nav-link"
                  id="nav-item_3"
                  to={paths.Contact_us}
                >
                  <i className="fas fa-file-signature" />
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <footer className="footer-1 fixed-bottom">
          <div className="footer-copyright">
            <div className="container">
              <div className="row align-items-end">
                <div className="col">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <p>Copyright {currentYear} - Pangeaps.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default NavBar;
