import { useLocation } from "react-router-dom";

export default function Location() {
  // Access the history object with useHistory()
  //const history = useHistory();

  // Access the location object with useLocation
  const location = useLocation();

  // Access the match object with useRouteMatch
  //const match = useRouteMatch();

  // Extract the URL parameters with useParams
  //const params = useParams();

  //console.log("location = " + location.pathname);

  /* console.log("history = " + history);
  console.log("match = " + match);
  console.log("params = " + params); */

  return location.pathname;
}
