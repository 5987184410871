import React, { useEffect, useState, useRef } from "react";
import emailjs from 'emailjs-com';
import "./contact_us.css";
import {gsap} from "gsap";


function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}
export default function Contact_us() {

  
  const [ifError, setIfError] = useState(true);

  const [nameError, setNameError] = useState(true);
  const [subjectError, setSubjectError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [messageError, setMessageError] = useState(true);

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [name_color, setName_color] = useState("");
  const [subject_color, setSubject_color] = useState("");
  const [email_color, setEmail_color] = useState("");
  const [message_color, setMessage_color] = useState("");


  //30°02'31.6"N 31°19'58.7"E
  
  const deg_N = 30;
  const mint_N = 2;
  const second_N = 31.6;
  const deg_E = 31;
  const mint_E = 19;
  const second_E = 58.7;
  const scale = 16;

  const map =`//maps.google.com/maps?output=embed&key=AIzaSyC6Pe_pAFIFE-1V92LaQFqMvNxonJ6WAVI&q=${deg_N}%C2%B0${mint_N}'${second_N}%22N%20${deg_E}%C2%B0${mint_E}'${second_E}%22E&z=${scale}&t=m`;

  //---------------------------sendEmail----------------------
  const form_1 = useRef();
  const form_2 = useRef();


  const sendEmail_1 = (e) => {
    e.preventDefault();

    /* console.log(ifError);
    console.log(form_1);
    console.log(form_2); */
    if (!ifError)  { 
    emailjs.sendForm('service_afwdia7', 'template_lnfethq', form_1.current, 'user_7sZGMN1AD3F0a9K4UXxbl')
      .then((result) => {
          console.log("ok");
          console.log(result.text);
          gsap.timeline()
            .set("#send-success", {y: -100})
            .to("#send-success", {display: "block", opacity: 1, y: 65, duration: 1})
            .to("#send-success", {opacity: 0, duration: 1}, "+=2");
          e.target.reset();    
          Array.from(document.querySelectorAll(".f-input")).forEach(
            input => (input.value = "")
          );
          setName(""); 
          setSubject(""); 
          setEmail(""); 
          setMessage("");
          setName_color("");
          setSubject_color("");
          setEmail_color("");
          setMessage_color("");
          setIfError(true);
      }, (error) => {
          console.log("error.text");
          console.log(error.text);
          gsap.timeline()
            .set("#send-error", {y: -100})
            .to("#send-error", {display: "block", opacity: 1, y: 70, duration: 1})
            .to("#send-error", {opacity: 0, duration: 1}, "+=2");
      });
    } else{
      //console.log("no");
      gsap.timeline()
      .set("#send-error", {y: 100})
      .to("#send-error", {display: "block", opacity: 1, y: -70, duration: 1})
      .to("#send-error", {opacity: 0, duration: 1}, "+=2");
    }
  };

  const sendEmail_2 = (e) => {
    e.preventDefault();

    //console.log(ifError);
    //console.log(form_1);
    //console.log(form_2);
    if (!ifError)  { 
    emailjs.sendForm('service_afwdia7', 'template_lnfethq', form_2.current, 'user_7sZGMN1AD3F0a9K4UXxbl')
      .then((result) => {
          console.log("ok");
          console.log(result.text);
          gsap.timeline()
            .set("#send-success", {y: 100})
            .to("#send-success", {display: "block", opacity: 1, y: -65, duration: 1})
            .to("#send-success", {opacity: 0, duration: 1}, "+=2");
          e.target.reset();    
          Array.from(document.querySelectorAll(".f-input")).forEach(
            input => (input.value = "")
          );
          setName(""); 
          setSubject(""); 
          setEmail(""); 
          setMessage("");
          setName_color("");
          setSubject_color("");
          setEmail_color("");
          setMessage_color("");
          setIfError(true);
          
      }, (error) => {
          console.log("error.text");
          console.log(error.text);
          gsap.timeline()
            .set("#send-error", {y: 100})
            .to("#send-error", {display: "block", opacity: 1, y: -70, duration: 1})
            .to("#send-error", {opacity: 0, duration: 1}, "+=2");
      });
    } else{
      //console.log("no");
      gsap.timeline()
      .set("#send-error", {y: 100})
      .to("#send-error", {display: "block", opacity: 1, y: -70, duration: 1})
      .to("#send-error", {opacity: 0, duration: 1}, "+=2");
    }
  };
  
  const onChangeInput = (event) => {

    if (event.target.name === "name")  {   
      setName(event.target.value)
      } else if (event.target.name === "subject"){   
        setSubject(event.target.value)
      } else if (event.target.name === "email"){   
        setEmail(event.target.value)
      } else if (event.target.name === "message"){   
        setMessage(event.target.value)
      }
}

const onSubmitInput = () => {
  
  if (name.length !== 0 || subject.length !== 0 || email.length !== 0 || message.length !== 0)  {
      
    document.getElementById('submit_1').disabled = false;
    document.getElementById('submit_1').removeAttribute("style"); 
    document.getElementById('submit_2').disabled = false;
    document.getElementById('submit_2').removeAttribute("style"); 

    if (name.length < 5)  {
      setName_color("#dfb2b4");
      setNameError(true);
    } else {
      setName_color("#8ef881");
      setNameError(false);
    }
    if (subject.length < 2)  {
      setSubject_color("#dfb2b4");
      setSubjectError(true);
    } else {
      setSubject_color("#8ef881");
      setSubjectError(false);
    }
    if (
      !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
      )  {
      setEmail_color("#dfb2b4");
      setEmailError(true);
    } else {
      setEmail_color("#8ef881");
      setEmailError(false);
    }
    if (message.length < 5)  {
      setMessage_color("#dfb2b4");
      setMessageError(true);
    } else {
      setMessage_color("#8ef881");
      setMessageError(false);
    }
  }else {
    document.getElementById('submit_1').disabled=true;
    document.getElementById('submit_1').style.backgroundColor="#a8a1a2";
    document.getElementById('submit_2').disabled=true;
    document.getElementById('submit_2').style.backgroundColor="#a8a1a2";
    setName_color("#ffffff");
    setSubject_color("#ffffff");
    setEmail_color("#ffffff");
    setMessage_color("#ffffff");
  }

  if (nameError || subjectError || emailError || messageError)  {
    setIfError(true);
  } else {
    setIfError(false);
  }
}


useEffect(() => { 
  
  
  if (name.length !== 0 || subject.length !== 0 || email.length !== 0 || message.length !== 0)  {
      
    document.getElementById('submit_1').disabled = false;
    document.getElementById('submit_1').removeAttribute("style"); 
    document.getElementById('submit_2').disabled = false;
    document.getElementById('submit_2').removeAttribute("style"); 

    if (name.length < 5)  {
      setName_color("#dfb2b4");
      setNameError(true);
    } else {
      setName_color("#8ef881");
      setNameError(false);
    }
    if (subject.length < 2)  {
      setSubject_color("#dfb2b4");
      setSubjectError(true);
    } else {
      setSubject_color("#8ef881");
      setSubjectError(false);
    }
    if (
      !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
      )  {
      setEmail_color("#dfb2b4");
      setEmailError(true);
    } else {
      setEmail_color("#8ef881");
      setEmailError(false);
    }
    if (message.length < 5)  {
      setMessage_color("#dfb2b4");
      setMessageError(true);
    } else {
      setMessage_color("#8ef881");
      setMessageError(false);
    }
  }else {
    document.getElementById('submit_1').disabled=true;
    document.getElementById('submit_1').style.backgroundColor="#a8a1a2";
    document.getElementById('submit_2').disabled=true;
    document.getElementById('submit_2').style.backgroundColor="#a8a1a2";
    setName_color("#ffffff");
    setSubject_color("#ffffff");
    setEmail_color("#ffffff");
    setMessage_color("#ffffff");
  }

  if (nameError || subjectError || emailError || messageError)  {
    setIfError(true);
  } else {
    setIfError(false);
  }

}, [name, subject, email, message, nameError, subjectError, emailError, messageError]);

  //--------------------------------------------------------------

  useTitle("PPS - Contact Us")

  useEffect(() => {

    gsap
      .timeline()
      .from(".map", { opacity: 0, x: -500, duration: 1 })
      .from(".box-1", { opacity: 0, x: 500, duration: .5 }, "-=1")
      .from(".get-in-touch", { scaleX: 0, transformOrigin: "left" }, "-=.5")
      .from(".shape-get-in-touch", { scaleX: 0, transformOrigin: "right" }, "-=.5")
      ;

  }, []);


  return (
    <div>
      <div id="contact"
        className="content">
        <section
          className="u-align-center-xs u-clearfix u-hidden-sm u-hidden-xs u-section-1"
          id="carousel_805f"
        >
          <div className="u-expanded-height-lg u-expanded-height-xl u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-palette-4-dark-3 u-shape u-shape-rectangle u-shape-1 shape-get-in-touch"></div>
          <div className="u-clearfix u-layout-wrap u-layout-wrap-1">
            <div className="u-gutter-0 u-layout">
              <div className="u-layout-row">
                <div
                  className="u-align-left u-container-style u-layout-cell u-left-cell u-size-18-lg u-size-24-sm u-size-24-xl u-size-24-xs u-size-60-md u-layout-cell-1 get-in-touch"
                >
                  <div className="u-container-layout u-valign-top u-container-layout-1">
                    <h2 className="u-custom-font u-font-oswald u-text u-text-body-alt-color u-text-1">
                      Get in touch!
                    </h2>
                    <div className="u-border-5 u-border-palette-2-base u-line u-line-horizontal u-line-1" />
                    <div className="u-form u-form-1">
                    <form ref={form_1} onSubmit={sendEmail_1}
                    className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                    style={{ padding: 0 }}
                    >
                          <input
                            style={{ backgroundColor: name_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            type="text"
                            placeholder="Enter your Name"
                            id="name-8db6"
                            name="name"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={name}
                            Value={name}
                          />
                          <input
                            style={{ backgroundColor: subject_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            type="text"
                            placeholder="Enter your Subject"
                            id="name-8db6"
                            name="subject"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={subject}
                          />
                          <input
                            style={{ backgroundColor: email_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            type="email"
                            placeholder="Enter a valid email address"
                            id="email-8db6"
                            name="email"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={email}
                          />
                          <textarea
                            style={{ backgroundColor: message_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            placeholder="Enter your message"
                            rows={4}
                            cols={50}
                            id="message-8db6"
                            name="message"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={message}
                          />
                        <input
                        id="submit_1"
                        className="f-submit"
                        type="submit" value="Send" />
                    </form>
{/*--------------------------------------------------------------------------------------------- */}
                        <div className="u-form-send-message u-form-send-success" id="send-success">
                          Thank you! Your message has been sent.{" "}
                        </div>
{/*--------------------------------------------------------------------------------------------- */}
                        <div className="u-form-send-error u-form-send-message" id="send-error">
                          Unable to send your message. Please fix errors then
                          try again.
                        </div>
{/*--------------------------------------------------------------------------------------------- */}
                    </div>
                  </div>
                </div>
                <div
                  id="box-1" className="u-align-left u-container-style u-hidden-md u-hidden-sm u-layout-cell u-size-19-sm u-size-19-xs u-size-20-xl u-size-24-lg u-size-60-md u-white u-layout-cell-2 box-1">
                  <div className="u-container-layout u-container-layout-2" />
                </div>
                <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-16-xl u-size-17-sm u-size-17-xs u-size-18-lg u-size-60-md u-white u-layout-cell-3 map">
                  <div className="u-container-layout u-valign-top u-container-layout-3">
                    <div
                      className="u-expanded u-grey-light-2 u-map u-map-1"
                    >
                      <div className="embed-responsive">
                        <iframe
                          className="embed-responsive-item"
                          src={map}
                          data-map="JTdCJTIycG9zaXRpb25UeXBlJTIyJTNBJTIybWFwLWFkZHJlc3MlMjIlMkMlMjJhZGRyZXNzJTIyJTNBJTIyMzAlQzIlQjAwMiczMS42JTVDJTIyTiUyMDMxJUMyJUIwMTknNTguNyU1QyUyMkUlMjIlMkMlMjJ6b29tJTIyJTNBMTYlMkMlMjJ0eXBlSWQlMjIlM0ElMjJyb2FkJTIyJTJDJTIybGFuZyUyMiUzQSUyMiUyMiUyQyUyMmFwaUtleSUyMiUzQSUyMkFJemFTeUM2UGVfcEFGSUZFLTFWOTJMYVFGcU12Tnhvbko2V0FWSSUyMiUyQyUyMm1hcmtlcnMlMjIlM0ElNUIlN0IlMjJwb3NpdGlvbiUyMiUzQSU3QiUyMmxhdCUyMiUzQTMwLjA3NDc1NzYzMzMxNTA5NSUyQyUyMmxuZyUyMiUzQTMxLjMxMzU4ODYxOTIzMjE3OCU3RCUyQyUyMnRpdGxlJTIyJTNBJTIyJTIyJTJDJTIyZGVzY3JpcHRpb24lMjIlM0ElMjIlMjIlMkMlMjJsaW5rVXJsJTIyJTNBJTIyJTIyJTJDJTIybGlua0NhcHRpb24lMjIlM0ElMjIlMjIlMkMlMjJ6b29tJTIyJTNBOCU3RCU1RCU3RA=="
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="u-clearfix u-gutter-0 u-layout-wrap u-layout-wrap-2">
            <div className="u-layout box-1">
              <div className="u-layout-col">
                <div className="u-size-30">
                  <div className="u-layout-row">
                    <div
                      className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-4"
                    >
                      <div className="u-container-layout u-container-layout-4">
                        <a
                          className="u-align-center u-border-3 u-border-black u-icon u-icon-circle u-spacing-19 u-text-black u-icon-1"
                          href="https://goo.gl/maps/keRMvEBLeBCd2Z7x5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 512 512"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-0ea2"
                            />
                          </svg>
                          <svg
                            id="svg-0ea2"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            className="u-svg-content"
                          >
                            <g>
                              <g>
                                <path d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257    c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22    C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34    C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180    C406.1,207.121,398.689,233.688,384.866,256.818z"></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z     M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2    C316.1,212.683,289.784,240.2,256,240.2z"></path>
                              </g>
                            </g>
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </a>
                        <h5 className="u-text u-text-grey-40 u-text-2">
                          Address:
                        </h5>
                        <p className="u-text u-text-3">
                          <a
                            href="https://goo.gl/maps/keRMvEBLeBCd2Z7x5"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-black u-btn-2"
                          >
                            25 Al Sheikh Taha Al Dinary Street, Nasr City,
                            Cairo, Egypt
                          </a>
                          <br />
                        </p>
                      </div>
                    </div>
                    <div
                      className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-5"
                    >
                      <div className="u-container-layout u-container-layout-5">
                        <a
                          className="u-align-center u-border-3 u-border-black u-icon u-icon-circle u-spacing-20 u-text-black u-icon-2"
                          href="tel:+20 100 723 6725"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 384 384"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-aaaa"
                            />
                          </svg>
                          <svg
                            id="svg-aaaa"
                            x="0px"
                            y="0px"
                            viewBox="0 0 384 384"
                            style={{ enableBackground: "new 0 0 384 384" }}
                            className="u-svg-content"
                          >
                            <g>
                              <g>
                                <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.906-3.719-23.323-0.833-30.438,6.417l-43.177,32.594    c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448    c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.812C13.823,0,0,13.823,0,30.812    C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z     M362.667,353.188c0,5.229-4.25,9.479-9.479,9.479c-182.99,0-331.854-148.865-331.854-331.854c0-5.229,4.25-9.479,9.479-9.479    h70.521c5.229,0,9.479,4.25,9.479,9.479c0,25.802,4.052,51.125,11.979,75.115c1.104,3.542,0.208,7.208-3.375,10.938L82.75,165.427    c-2.458,3.26-2.844,7.625-1,11.26c29.927,58.823,66.292,95.188,125.531,125.542c3.604,1.885,8.021,1.49,11.292-0.979    l49.677-37.635c2.51-2.51,6.271-3.406,9.667-2.25c24.156,7.979,49.479,12.021,75.271,12.021c5.229,0,9.479,4.25,9.479,9.479    V353.188z"></path>
                              </g>
                            </g>
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </a>
                        <h5 className="u-text u-text-grey-40 u-text-4">
                          Call us:
                        </h5>
                        <p className="u-text u-text-5">
                          +20 100 723 6725
                          <br />
                          +20 106 966 1767
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-size-30">
                  <div className="u-layout-row">
                    <div
                      className="u-container-style u-layout-cell u-size-30 u-layout-cell-6"
                    >
                      <div className="u-container-layout u-container-layout-6">
                        <a
                          className="u-border-3 u-border-black u-icon u-icon-circle u-spacing-20 u-text-black u-icon-3"
                          href="mailto:info@pangeaps.com"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 479.058 479.058"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-2500"
                            />
                          </svg>
                          <svg
                            id="svg-2500"
                            viewBox="0 0 479.058 479.058"
                            className="u-svg-content"
                          >
                            <path d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"></path>
                          </svg>
                        </a>
                        <h5 className="u-align-center u-text u-text-grey-40 u-text-6">
                          Email:
                        </h5>
                        <a
                          href="mailto:info@pangeaps.com"
                          className="u-align-center u-btn u-button-style u-none u-text-black u-text-hover-palette-2-base u-btn-3"
                        >
                          info@pangeaps.com
                        </a>
                      </div>
                    </div>
                    <div
                      className="u-container-style u-layout-cell u-size-30 u-layout-cell-7"
                    >
                      <div className="u-container-layout u-container-layout-7">
                        <a
                          className="u-border-2 u-border-black u-icon u-icon-circle u-spacing-10 u-text-black u-icon-4"
                          href="https://www.facebook.com/Pangea-Petroleum-Services-110925800570010"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 24 24"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-1970"
                            />
                          </svg>
                          <svg
                            className="u-svg-content"
                            viewBox="0 0 24 24"
                            id="svg-1970"
                          >
                            <path
                              d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-6.932 0-5.046 7.85-5.322 9h-3.487v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877c.188-2.824-.761-5.016 2.051-5.016z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                        <h5 className="u-align-center u-text u-text-grey-40 u-text-7">
                          Follow Us
                        </h5>
                        <a
                          className="u-border-2 u-border-black u-icon u-icon-circle u-spacing-10 u-text-black u-icon-5"
                          href="https://www.linkedin.com/company/70459150/admin/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 512 512"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-dd53"
                            />
                          </svg>
                          <svg
                            className="u-svg-content"
                            viewBox="0 0 512 512"
                            id="svg-dd53"
                          >
                            <g>
                              <path d="m17.813 161.387h112.571v350.613h-112.571z" />
                              <path d="m74.099 0c-35.344 0-64.099 28.755-64.099 64.099 0 35.345 28.755 64.1 64.099 64.1 35.345 0 64.1-28.755 64.1-64.1-.001-35.344-28.756-64.099-64.1-64.099z"></path>
                              <path d="m501.965 267.242c-1.486-65.345-54.977-116.531-121.775-116.531-36.983 0-71.326 16.389-94.361 44.446v-33.771h-107.03v350.614h112.572v-202.667c0-27.002 21.968-48.97 48.97-48.97 27.001 0 48.969 21.965 48.969 48.972l.12 202.665h112.57z"></path>
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="u-align-center-xs u-clearfix u-hidden-lg u-hidden-md u-hidden-xl u-section-2"
          id="carousel_8972"
        >
          <div className="u-expanded-height-lg u-expanded-height-xl u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-palette-4-dark-3 u-shape u-shape-rectangle u-shape-1 u-expanded-height-lg u-expanded-height-xl u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-palette-4-dark-3 u-shape u-shape-rectangle u-shape-1 shape-get-in-touch"></div>
          <div className="u-clearfix u-layout-wrap u-layout-wrap-1">
            <div className="u-gutter-0 u-layout">
              <div className="u-layout-row">
                <div
                  className="u-align-left u-container-style u-layout-cell u-left-cell u-size-18-lg u-size-24-sm u-size-24-xl u-size-24-xs u-size-60-md u-layout-cell-1 get-in-touch"
                >
                  <div className="u-container-layout u-valign-top u-container-layout-1">
                    <h2 className="u-custom-font u-font-oswald u-text u-text-body-alt-color u-text-1">
                      Get in touch!
                    </h2>
                    <div className="u-border-5 u-border-palette-2-base u-line u-line-horizontal u-line-1" />
                    <div className="u-form u-form-1">
                      
                    <form ref={form_2} onSubmit={sendEmail_2}
                    className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                    style={{ padding: 0 }}
                    >
                          <input
                            style={{ backgroundColor: name_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            type="text"
                            placeholder="Enter your Name"
                            id="name-8db6"
                            name="name"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={name}
                          />
                          <input
                            style={{ backgroundColor: subject_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            type="text"
                            placeholder="Enter your Subject"
                            id="name-8db6"
                            name="subject"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={subject}
                          />
                          <input
                            style={{ backgroundColor: email_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            type="email"
                            placeholder="Enter a valid email address"
                            id="email-8db6"
                            name="email"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={email}
                          />
                          <textarea
                            style={{ backgroundColor: message_color}}
                            onBlur={onSubmitInput}
                            onChange={onChangeInput}
                            placeholder="Enter your message"
                            rows={4}
                            cols={50}
                            id="message-8db6"
                            name="message"
                            className="f-input u-input u-input-rectangle u-white"
                            required
                            defaultValue={message}
                          />
                        <input
                        id="submit_2"
                        className="f-submit"
                        type="submit" value="Send" />
                    </form>
{/*--------------------------------------------------------------------------------------------- */}
                        <div className="u-form-send-message u-form-send-success" id="send-success">
                          Thank you! Your message has been sent.{" "}
                        </div>
{/*--------------------------------------------------------------------------------------------- */}
                        <div className="u-form-send-error u-form-send-message" id="send-error">
                          Unable to send your message. Please fix errors then
                          try again.
                        </div>
{/*--------------------------------------------------------------------------------------------- */}
                    </div>
                  </div>
                </div>
                <div className="u-align-left u-container-style u-hidden-md u-hidden-sm u-layout-cell u-size-19-sm u-size-19-xs u-size-20-xl u-size-24-lg u-size-60-md u-white u-layout-cell-2">
                  <div className="u-container-layout u-container-layout-2" />
                </div>
                <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-16-xl u-size-17-sm u-size-17-xs u-size-18-lg u-size-60-md u-white u-layout-cell-3 map">
                  <div className="u-container-layout u-valign-top u-container-layout-3">
                    <div
                      className="u-expanded u-grey-light-2 u-map u-map-1"
                      data-animation-name="fadeIn"
                      data-animation-duration={1000}
                      data-animation-delay={0}
                      data-animation-direction
                    >
                      <div className="embed-responsive">
                        <iframe
                          className="embed-responsive-item"
                          src={map}
                          data-map="JTdCJTIycG9zaXRpb25UeXBlJTIyJTNBJTIybWFwLWFkZHJlc3MlMjIlMkMlMjJhZGRyZXNzJTIyJTNBJTIyMzAlQzIlQjAwMiczMS42JTVDJTIyTiUyMDMxJUMyJUIwMTknNTguNyU1QyUyMkUlMjIlMkMlMjJ6b29tJTIyJTNBMTYlMkMlMjJ0eXBlSWQlMjIlM0ElMjJyb2FkJTIyJTJDJTIybGFuZyUyMiUzQSUyMiUyMiUyQyUyMmFwaUtleSUyMiUzQSUyMkFJemFTeUM2UGVfcEFGSUZFLTFWOTJMYVFGcU12Tnhvbko2V0FWSSUyMiUyQyUyMm1hcmtlcnMlMjIlM0ElNUIlN0IlMjJwb3NpdGlvbiUyMiUzQSU3QiUyMmxhdCUyMiUzQTMwLjA3NDc1NzYzMzMxNTA5NSUyQyUyMmxuZyUyMiUzQTMxLjMxMzU4ODYxOTIzMjE3OCU3RCUyQyUyMnRpdGxlJTIyJTNBJTIyJTIyJTJDJTIyZGVzY3JpcHRpb24lMjIlM0ElMjIlMjIlMkMlMjJsaW5rVXJsJTIyJTNBJTIyJTIyJTJDJTIybGlua0NhcHRpb24lMjIlM0ElMjIlMjIlMkMlMjJ6b29tJTIyJTNBOCU3RCU1RCU3RA=="
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="u-clearfix u-gutter-0 u-layout-wrap u-layout-wrap-2">
            <div className="u-layout box-1">
              <div className="u-layout-col">
                <div className="u-size-30">
                  <div className="u-layout-row">
                    <div
                      className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-4"
                    >
                      <div className="u-container-layout u-container-layout-4">
                        <span
                          className="u-align-center u-border-3 u-border-black u-icon u-icon-circle u-spacing-19 u-text-black u-icon-1"
                          data-href="https://goo.gl/maps/keRMvEBLeBCd2Z7x5"
                          data-target="_blank"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 512 512"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-0ea2"
                            />
                          </svg>
                          <svg
                            id="svg-0ea2"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            className="u-svg-content"
                          >
                            <g>
                              <g>
                                <path d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257    c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22    C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34    C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180    C406.1,207.121,398.689,233.688,384.866,256.818z"></path>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z     M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2    C316.1,212.683,289.784,240.2,256,240.2z"></path>
                              </g>
                            </g>
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </span>
                        <h5 className="u-text u-text-grey-40 u-text-2">
                          Address:
                        </h5>
                        <p className="u-text u-text-3">
                          <a
                            href="https://goo.gl/maps/keRMvEBLeBCd2Z7x5"
                            target="_blank"
                            c
                            className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-black u-btn-2"
                          >
                            25 Al Sheikh Taha Al Dinary Street, Nasr City,
                            Cairo, Egypt
                          </a>
                          <br />
                        </p>
                      </div>
                    </div>
                    <div
                      className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-5"
                    >
                      <div className="u-container-layout u-container-layout-5">
                        <span
                          className="u-align-center u-border-3 u-border-black u-icon u-icon-circle u-spacing-20 u-text-black u-icon-2"
                          data-href="tel:+20 100 723 6725"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 384 384"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-aaaa"
                            />
                          </svg>
                          <svg
                            id="svg-aaaa"
                            x="0px"
                            y="0px"
                            viewBox="0 0 384 384"
                            style={{ enableBackground: "new 0 0 384 384" }}
                            className="u-svg-content"
                          >
                            <g>
                              <g>
                                <path d="M353.188,252.052c-23.51,0-46.594-3.677-68.469-10.906c-10.906-3.719-23.323-0.833-30.438,6.417l-43.177,32.594    c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208,11.167-20.198,7.635-31.448    c-7.26-21.99-10.948-45.063-10.948-68.583C132.146,13.823,118.323,0,101.333,0H30.812C13.823,0,0,13.823,0,30.812    C0,225.563,158.438,384,353.188,384c16.99,0,30.813-13.823,30.813-30.813v-70.323C384,265.875,370.177,252.052,353.188,252.052z     M362.667,353.188c0,5.229-4.25,9.479-9.479,9.479c-182.99,0-331.854-148.865-331.854-331.854c0-5.229,4.25-9.479,9.479-9.479    h70.521c5.229,0,9.479,4.25,9.479,9.479c0,25.802,4.052,51.125,11.979,75.115c1.104,3.542,0.208,7.208-3.375,10.938L82.75,165.427    c-2.458,3.26-2.844,7.625-1,11.26c29.927,58.823,66.292,95.188,125.531,125.542c3.604,1.885,8.021,1.49,11.292-0.979    l49.677-37.635c2.51-2.51,6.271-3.406,9.667-2.25c24.156,7.979,49.479,12.021,75.271,12.021c5.229,0,9.479,4.25,9.479,9.479    V353.188z"></path>
                              </g>
                            </g>
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </span>
                        <h5 className="u-text u-text-grey-40 u-text-4">
                          Call us:
                        </h5>
                        <p className="u-text u-text-5">
                          +20 100 723 6725
                          <br />
                          +20 106 966 1767
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="u-size-30">
                  <div className="u-layout-row">
                    <div
                      className="u-container-style u-layout-cell u-size-30 u-layout-cell-6"
                    >
                      <div className="u-container-layout u-container-layout-6">
                        <span
                          className="u-border-3 u-border-black u-icon u-icon-circle u-spacing-20 u-text-black u-icon-3"
                          data-href="mailto:info@pangeaps.com"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 479.058 479.058"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-2500"
                            />
                          </svg>
                          <svg
                            id="svg-2500"
                            viewBox="0 0 479.058 479.058"
                            className="u-svg-content"
                          >
                            <path d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"></path>
                          </svg>
                        </span>
                        <h5 className="u-align-center u-text u-text-grey-40 u-text-6">
                          Email:
                        </h5>
                        <a
                          href="mailto:info@pangeaps.com"
                          className="u-align-center u-btn u-button-style u-none u-text-black u-text-hover-palette-2-base u-btn-3"
                        >
                          info@pangeaps.com
                        </a>
                      </div>
                    </div>
                    <div
                      className="u-container-style u-layout-cell u-size-30 u-layout-cell-7"
                    >
                      <div className="u-container-layout u-container-layout-7">
                        <span
                          className="u-border-2 u-border-black u-icon u-icon-circle u-spacing-10 u-text-black u-icon-4"
                          data-href="https://www.facebook.com/Pangea-Petroleum-Services-110925800570010"
                          data-target="_blank"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 24 24"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-1970"
                            />
                          </svg>
                          <svg
                            className="u-svg-content"
                            viewBox="0 0 24 24"
                            id="svg-1970"
                          >
                            <path
                              d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-6.932 0-5.046 7.85-5.322 9h-3.487v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877c.188-2.824-.761-5.016 2.051-5.016z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <h5 className="u-align-center u-text u-text-grey-40 u-text-7">
                          Follow Us
                        </h5>
                        <span
                          className="u-border-2 u-border-black u-icon u-icon-circle u-spacing-10 u-text-black u-icon-5"
                          data-href="https://www.linkedin.com/company/70459150/admin/"
                          data-target="_blank"
                        >
                          <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 512 512"
                            style={{}}
                          >
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="#svg-dd53"
                            />
                          </svg>
                          <svg
                            className="u-svg-content"
                            viewBox="0 0 512 512"
                            id="svg-dd53"
                          >
                            <g>
                              <path d="m17.813 161.387h112.571v350.613h-112.571z" />
                              <path d="m74.099 0c-35.344 0-64.099 28.755-64.099 64.099 0 35.345 28.755 64.1 64.099 64.1 35.345 0 64.1-28.755 64.1-64.1-.001-35.344-28.756-64.099-64.1-64.099z"></path>
                              <path d="m501.965 267.242c-1.486-65.345-54.977-116.531-121.775-116.531-36.983 0-71.326 16.389-94.361 44.446v-33.771h-107.03v350.614h112.572v-202.667c0-27.002 21.968-48.97 48.97-48.97 27.001 0 48.969 21.965 48.969 48.972l.12 202.665h112.57z"></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

}

